/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Dialog,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  Typography,
  Grid,
  Button,
  Popover,
  ButtonBase,
  FormHelperText,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { TwitterPicker } from 'react-color';
import hexToRgba from 'hex-to-rgba';
import { useFormik } from 'formik';
import * as yup from 'yup';

// import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import Alert from 'src/components/alert';
import { CustomPaper } from 'src/components/autocomplete';
import TooltipedText from 'src/components/tooltipedText';

// import DeleteIcon from 'src/assets/icons/close.svg';
import EditIcon from 'src/assets/icons/edit.svg';
import DeleteIcon from 'src/assets/icons/delete.svg';
import { getId } from 'src/helpers/strings';
import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { useRepLabelsData } from '../state/hooks';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(5),
    width: '100%',
  },
  row: {
    backgroundColor: '#F6F6F6',
    padding: theme.spacing(3),
    borderRadius: 4,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  labelColor: {
    height: 20,
    width: 20,
    borderRadius: 4,
  },
  loadingContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const LabelColor = ({ value, onChange, onBlur, label, error }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState(value);
  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = colorString => {
    setColor(colorString.hex);
    handleClose();
  };

  useEffect(() => {
    onChange(label, color);
  }, [color]);

  return (
    <TextField
      size="small"
      label={label}
      variant="outlined"
      color="secondary"
      name="color"
      // disabled
      error={error}
      onBlur={onBlur}
      value={color.toUpperCase()}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ButtonBase
              onClick={handleClick}
              className={classes.labelColor}
              style={{ backgroundColor: hexToRgba(color, 0.6), border: `1px solid ${color}` }}
            />
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <TwitterPicker triangle="hide" color={color} onChange={handleChange} />
            </Popover>
          </InputAdornment>
        ),
      }}
    />
  );
};

const LabelRow = ({ id, label, color, onEdit, onDelete }) => {
  const classes = useStyles();
  const [isView, setIsView] = useState(true);

  const formik = useFormik({
    initialValues: {
      id,
      color,
      label,
    },
    validationSchema: yup.object().shape({
      color: yup.string().required(),
      label: yup.string().required(),
    }),
    onSubmit: (values, actions) => {
      actions.resetForm({ values });
      actions.setSubmitting(false);
      onEdit(values);
    },
  });

  const toggleRepresentation = () => {
    setIsView(isView => !isView);
  };

  const handleSubmitEdit = () => {
    toggleRepresentation();

    formik.submitForm();
  };

  const handleCancelEdit = () => {
    toggleRepresentation();

    formik.resetForm({ values: { id, color, label } });
  };

  return (
    <div className={classes.row}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <div
            style={{ backgroundColor: hexToRgba(color, 0.6), border: `1px solid ${color}` }}
            className={classes.labelColor}
          />
        </Grid>

        {isView ? (
          <>
            <Grid item xs>
              <Typography>{label}</Typography>
            </Grid>
            <Grid item>
              <ButtonBase onClick={toggleRepresentation}>
                <EditIcon />
              </ButtonBase>
            </Grid>
            <Grid item>
              <ButtonBase onClick={onDelete}>
                <DeleteIcon />
              </ButtonBase>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs>
              <TextField
                id="label"
                variant="outlined"
                label="Label"
                size="small"
                color="secondary"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.label}
                error={formik.errors.label && formik.touched.label}
              />

              {formik.errors.label && formik.touched.label && (
                <FormHelperText component="div">
                  <Alert severity="error">{formik.errors.label}</Alert>
                </FormHelperText>
              )}
            </Grid>
            <Grid item>
              <IconButton color="primary" size="small" onClick={handleSubmitEdit}>
                <DoneIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="primary" size="small" onClick={handleCancelEdit}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default function Label({
  data,
  value,
  onBlur,
  label,
  error,
  name,
  onChange,
  onCreateLabel,
  onRemoveLabel,
  onUpdateLabel,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { isLoading } = useRepLabelsData();

  const formik = useFormik({
    initialValues: {
      id: 100,
      color: '#7BDCB5',
      label: '',
    },
    validationSchema: yup.object().shape({
      color: yup.string(),
      label: yup.string(),
    }),
    onSubmit: (values, actions) => {
      actions.resetForm();
      actions.setSubmitting(false);
      onCreateLabel({ ...values, id: getId() });
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleEdit = repLabel => {
    onUpdateLabel(repLabel);
  };

  const handleDelete = ({ id }) => () => {
    onRemoveLabel(id);
  };

  const handleSelect = (event, labelItem) => {
    const { id } = labelItem;

    onChange('label', id);
  };

  const getValue = () => {
    const labelItem = data.find(item => item.id === value) ?? null;

    return labelItem;
  };

  useEffect(() => {
    formik.resetForm();
  }, [open]);

  return (
    <>
      <Autocomplete
        options={data}
        getOptionLabel={option => option.label ?? ''}
        disableClearable
        PaperComponent={CustomPaper}
        onChange={handleSelect}
        value={getValue()}
        renderOption={option => (
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <div
                style={{ backgroundColor: hexToRgba(option.color, 0.6), border: `1px solid ${option.color}` }}
                className={classes.labelColor}
              />
            </Grid>
            <Grid item xs>
              <Typography>{option.label}</Typography>
            </Grid>
          </Grid>
        )}
        renderInput={params => (
          <TextField
            {...params}
            size="small"
            label={label}
            variant="outlined"
            color="secondary"
            name={name}
            onBlur={onBlur}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <IconButton color="primary" size="small" onClick={handleOpen}>
                      <EditIcon />
                    </IconButton>
                  </InputAdornment>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <Dialog onClose={handleClose} open={open} fullWidth>
        <Paper className={classes.paper}>
          <TooltipedText tooltipText="Add and delete your reps here" gutterBottom={3}>
            <Typography variant="h6">Label</Typography>
          </TooltipedText>

          <LoadingContainer classNames={classes.loadingContainer} loading={isLoading}>
            {
              data.map((label, index) => (
                (
                  label?.id !== '' && (
                    <LabelRow key={index} {...label} onEdit={handleEdit} onDelete={handleDelete(label)} />
                  )
                )
              ))
            }
          </LoadingContainer>

          <div className={classes.row}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <LabelColor
                  label="color"
                  value={formik.values.color}
                  onChange={formik.setFieldValue}
                  onBlur={formik.handleBlur}
                  error={formik.touched.color && Boolean(formik.errors.color)}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  size="small"
                  label="Label"
                  variant="outlined"
                  color="secondary"
                  name="label"
                  fullWidth
                  value={formik.values.label}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.label && Boolean(formik.errors.label)}
                />
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  disabled={!formik.isValid || formik.isSubmitting}
                  onClick={formik.handleSubmit}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </div>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                // disabled={!formik.isValid || formik.isSubmitting}
                onClick={handleClose}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
}
