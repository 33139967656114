/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';

import { useRepsActions, useRepLabelsData } from '../../state/hooks';
import { RepItem } from './RepItem';

export const RepsList = ({ data }) => {
  const { removeRep, updateRep } = useRepsActions();
  const { data: labels } = useRepLabelsData();

  return data.map(value => (
    <RepItem
      key={value.id}
      value={value}
      labels={labels}
      onRemoveRep={removeRep}
      onUpdateRep={updateRep}
    />
  ));
};
