import React from 'react';

import SEO from 'src/components/seo';
import { RoutingRules } from 'src/features/routingRules/RoutingRules';

export default function RoutingRulesPage() {
  return (
    <>
      <SEO title="Settings | Routing rules" />

      <RoutingRules />
    </>
  );
}
