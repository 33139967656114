/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Button, Divider, Grid, makeStyles, Typography } from '@material-ui/core';

import TooltipedText from 'src/components/tooltipedText';
import SwitchTag from 'src/components/switchTag';

const useStyles = makeStyles(theme => ({
  spacer: {
    marginBottom: theme.spacing(3),
  },
  spacerTop: {
    marginTop: theme.spacing(3),
  },
  label: {
    backgroundColor: '#3C3D52',
    border: '1px solid #202239',
    color: '#fff',
    padding: theme.spacing(1, 1.3),
    width: 78,
  },
  spacer2: {
    marginBottom: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  callAttemptsLabel: {
    marginBottom: theme.spacing(3),
    width: 325,
  },
}));

const callDistributionTypes = [];
callDistributionTypes[1] = 'Priority ringing';
callDistributionTypes[2] = 'Round robin';
callDistributionTypes[3] = 'Simultaneous';

export default function ViewCallSettings({ groupId, data, onEdit, isEditAllowed }) {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3} className={classes.spacer}>
        <Grid item md>
          <TooltipedText tooltipText="Limits the number of calls generated at same time">
            <Typography variant="subtitle1">
              Concurrent Calls
            </Typography>
          </TooltipedText>

          <Typography className={classes.spacerTop}>{data.concurrent_calls}</Typography>
        </Grid>

        <Grid item>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item md>
          <TooltipedText tooltipText="Choose how you want to deliver your lead calls to your Reps/Groups">
            <Typography variant="subtitle1">
              Call Distribution Type
            </Typography>
          </TooltipedText>

          <Grid item className={classes.spacerTop}>
            {
              callDistributionTypes.map((distributionType, idx) => (
                <Grid key={`call-distribution-${groupId}-${distributionType}`} className={classes.spacer2} container spacing={2} alignItems="center">
                  <Grid item xs="auto">
                    <SwitchTag checked={data.call_distribution_type != idx} />
                  </Grid>

                  <Grid item xs>
                    <Typography>{distributionType}</Typography>
                  </Grid>
                </Grid>
              ))

            }
          </Grid>
        </Grid>
      </Grid>

      {isEditAllowed &&
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" color="primary" size="large" disableElevation onClick={onEdit}>
              Edit
            </Button>
          </Grid>
        </Grid>
      }
    </>
  );
}
