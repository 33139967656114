/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { makeStyles } from '@material-ui/core';

import { useFetchReps, useRepsData } from 'src/features/routingRules/rep/state/hooks';
import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { GroupRepsInput } from './GroupRepsInput';

const useStyles = makeStyles(() => ({
  loadingContainer: {
    minHeight: '100%',
  },
}));

export const GroupReps = repsProps => {
  const classes = useStyles();

  const { data, isLoading } = useRepsData();

  useFetchReps();

  return (
    <LoadingContainer classNames={classes.loadingContainer} loading={isLoading}>
      <GroupRepsInput {...repsProps} data={data} />
    </LoadingContainer>
  );
};
