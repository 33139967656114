/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { GroupList } from './list/GroupList';

export const GroupListContainer = ({ data, isLoading }) => {
  return (
    <LoadingContainer height={74} type="groups" loading={isLoading}>
      <GroupList data={data} />
    </LoadingContainer>
  );
};
