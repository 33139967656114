import React from 'react';
import { Button, Divider, Grid, makeStyles, Typography } from '@material-ui/core';

import GroupRepsList from '../../createGroup/list/GroupReps';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingBottom: theme.spacing(5),
  },
  spacer: {
    marginBottom: theme.spacing(3),
  },
}));

export default function ViewGroup({ data, onEdit, isEditAllowed }) {
  const classes = useStyles();

  const { location } = data;

  return (
    <>
      <Grid container spacing={4} className={classes.spacer}>
        <Grid item xs={12} md={9}>
          <Typography variant="subtitle1" className={classes.spacer}>
            Reps
          </Typography>

          {Boolean(data.reps.length) && (
            <div className={classes.wrapper}>
              <Grid container alignItems="center" spacing={2}>
                <GroupRepsList selected={data.reps} view />
              </Grid>
            </div>
          )}
        </Grid>

        <Grid item>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={12} md>
          <Typography variant="subtitle1" className={classes.spacer}>
            Routing Rule
          </Typography>

          <Typography>
            {location && location.name ? location.name : 'No rule(s)'}
          </Typography>
        </Grid>
      </Grid>

      {isEditAllowed &&
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" color="primary" size="large" disableElevation onClick={onEdit}>
              Edit
            </Button>
          </Grid>
        </Grid>
      }
    </>
  );
}
