/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';

import checkRole from 'src/helpers/aclHelper';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import Tab from 'src/components/tab';
import EditViewGroup from '../editViewGroup';
import CallSchedule from '../callSchedule';
import CallSettings from '../callSettings';
import ConcurentCall from '../concurentCall';

import { useGroupActions } from '../../state/hooks';

const useMountEffect = fun => useEffect(fun, []);

export const GroupListItem = ({ groupId, data, loading, isOpen, anchor }) => {
  const [isExpanded, setIsExpanded] = useState(isOpen);
  const [isAllowed, setIsAllowed] = useState(false);

  const reattempteRef = useRef(null);
  const scheduleRef = useRef(null);

  const { name, calls_paused } = data;

  const { updateGroupCallsPaused, deleteGroup, cloneGroup } = useGroupActions();

  const executeReattemptScroll = () => {
    if (anchor !== 'call-attempt') {
      return;
    }
    return reattempteRef?.current?.scrollIntoView()
  };

  const executeScheduleScroll = () => {
    if (anchor !== 'call-schedule') {
      return;
    }
    return scheduleRef?.current?.scrollIntoView()
  };

  useMountEffect(executeReattemptScroll);
  useMountEffect(executeScheduleScroll);

  const handleExpand = () => {
    setIsExpanded(expanded => !expanded);
  };

  const handleDeleteGroup = (params) => {
    deleteGroup(params);
  };

  const handleCloneGroup = (params) => {
    cloneGroup(params);
  };

  const handleChangeCallsPaused = (value) => {
    const request = {
      ...data,
      calls_paused: (value === true ? 1 : 0),
    };

    updateGroupCallsPaused(request);
  };

  const checkCallsPaused = (value) => {
    const responce = value == 1;

    return responce;
  };

  useEffect(() => {
    setIsAllowed(checkRole(['admin', 'manager']));
  }, []);

  return (
    <LoadingContainer key={`loader-${groupId}`} height={74} type="group" loading={loading}>
      <Tab
        // title={`${name} (ID: ${groupId})`}
        title={`${name}`}
        onClick={handleExpand}
        // callsPaused={checkCallsPaused(calls_paused)}
        // onChangeCallsPaused={handleChangeCallsPaused}
        expanded={isExpanded}
        key={`group-tab-${groupId}`}
        groupId={groupId}
        deleteGroup={handleDeleteGroup}
        cloneGroup={handleCloneGroup}
        color={data.color ?? '#9900EF'}
        isEditAllowed={isAllowed}
      >
        {
          isExpanded
            ? (<>
                <EditViewGroup groupId={groupId} data={data} isEditAllowed={isAllowed} />

                <ConcurentCall groupId={groupId} data={data} isEditAllowed={isAllowed} />

                <div style={{ position: 'relative' }}>
                  <div ref={reattempteRef} style={{ position: 'absolute', top: '-100px' }}></div>

                  <CallSettings groupId={groupId} data={data} isEditAllowed={isAllowed} />
                </div>

                <div style={{ position: 'relative' }}>
                  <div ref={scheduleRef} style={{ position: 'absolute', top: '-10px' }}></div>

                  <CallSchedule key={`sheduller-${groupId}`} groupId={groupId} data={data} isEditAllowed={isAllowed} />
                </div>
              </>)
            : (<></>)
        }
      </Tab>
    </LoadingContainer>
  );
};
