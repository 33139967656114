/* eslint-disable camelcase */
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import AddIcon from '@material-ui/icons/Add';
import {
  Button,
  ButtonBase,
  Grid,
  makeStyles,
  TextField,
  MenuItem,
  Typography,
} from '@material-ui/core';
import Switch from 'src/components/switch';
import TooltipedText from 'src/components/tooltipedText';

import DeleteIcon from 'src/assets/icons/close.svg';

import { getSettingsData } from 'src/features/me/state/selectors';

const useStyles = makeStyles(theme => ({
  spacer: {
    marginBottom: theme.spacing(3),
  },
  spacerTop: {
    marginTop: theme.spacing(3),
  },
  parkedCalls: {
    maxWidth: 216,
  },
  concurrentCall: {
    maxWidth: 166
  },
  callDistribution: {
    maxWidth: 256
  },
  hover: {
    display: 'flex',
    marginTop: '20px',

    '&:hover fieldset': {
      borderColor: 'inherit',
    },
    '&:active fieldset': {
      borderColor: 'inherit',
      borderWidth: '10px',
    },
  },
  flex: {
    display: 'flex',
  },
  flexDirectoinReverse: {
    display: 'flex',
    order: 1,
  },
  hideLeft: {
    '& fieldset': {
      borderLeft: 'none',
      borderRadius: '0px 5px 5px 0px',
    },
  },
  hideRight: {
    '& fieldset': {
      borderRight: 'none',
      borderRadius: '5px 0px 0px 5px',
    },
  },
  switch: {
    // marginLeft: 20,
  },
  dayWrap: {
    background: '#F8F8F8',
    border: '1px solid #F2F2F2',
    borderRadius: '3px',
    padding: '20px 20px 0 20px',
    marginBottom: '38px',
    position: 'relative',
  },
  label: {
    backgroundColor: '#98C1D3',
    color: '#fff',
    padding: '9px 12px',
    position: 'relative',
    fontWeight: '400',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '45px',

  },
  labelFirst: {
    backgroundColor: '#FEF077',
    // border: '1px solid #202239',
    color: '#202239',
    padding: '9px 12px',
    position: 'relative',
    fontWeight: '400',
    fontSize: '14px',
    height: '45px',
    alignItems: 'center',
    display: 'flex',
    width: 'calc(100% + 1px)',
  },
  labelTime: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#000000',
    marginTop: '20px',
    height: '41px',
  },
  labelTooltip: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#000000',
    // marginTop: '20px',
  },
  labelDay: {
    padding: '9px 12px',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'left',
  },
  labelDivider: {
    top: '50%',
    right: '-40px',
    border: '1px solid #000',
    position: 'absolute',
    maxWidth: '40px',
    width: '100%',
  },
  labelDividerLast: {
    top: '50%',
    left: '-40px',
    border: '1px solid #000',
    position: 'absolute',
    maxWidth: '40px',
    width: '100%',
  },
  labelAttempt: {
    width: '100%',
  },
  labelDayColumn: {
    width: '110px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '8px',
  },
  attemptList: {
    maxWidth: '950px'
  },
  attemptListWrap: {
    marginRight: '40px',
    marginBottom: '20px',
    width: '200px',
  },
  attemptListWrapNoMargin: {
    marginBottom: '20px',
    width: '200px',
  },
  spacer2: {
    marginBottom: theme.spacing(1),
  },
  spacer3: {
    marginBottom: '25px',
  },
  callAttemptsLabel: {
    marginBottom: theme.spacing(3),
    width: 325,
  },
  dayDelete: {
    position: 'absolute',
    right: '12px',
    bottom: '15px',
  },
  addIconWrapper: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    width: 20,
    height: 20,
    position: 'relative',
  },
  addIcon: {
    fill: 'white',
    stroke: 'white',
    width: 11,
    height: 11,
    strokeWidth: 7,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  addIconLabel: {
    color: theme.palette.primary.main,
  },
  addIconPosition: {
    position: 'relative',
    height: '45px',
    // marginLeft: '40px',
    marginBottom: '20px',
  },
  newWorkingTimeWrapper: {
    display: 'flex',
    width: 'fit-content',
    marginTop: theme.spacing(1.75),
    cursor: 'pointer',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(0.6),
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
    // '&:hover': {
    //   '&>.MuiTypography-root': {
    //     borderBottom: '1px solid',
    //     borderBottomColor: theme.palette.primary.main,
    //   },
    // },
  },
  reatepmtsIcon: {
    marginRight: '10px',
    width: '14px',
    height: '14px',
    background: '#8DB1BE',
  }
}));

export default function EditCallSettings({ groupId, data, onSave, onCancel }) {
  const classes = useStyles();
  const advancedSettings = useSelector(getSettingsData);
  const inputProps = { min: 1 };
  const [reatteptsCounterLimit, setReatteptsCounterLimit] = useState(false);
  const [reatteptsCounter, setReatteptsCounter] = useState(data.reattempts_timeouts?.length + 1);
  const [addNewDay, setAddNewDay] = useState(false);

  const formik = useFormik({
    initialValues: {
      reattempts_disabled: false,
      reattempts: [],
    },
    validationSchema: yup.object().shape({
      reattempts: yup.array().of(
        yup.object().shape({
          attempts: yup.array().of(
            yup.object().shape({
              hours: yup.number().required(),
              minutes: yup.number().required(),
            }),
          ),
        }),
      ),
    }),
    onSubmit: values => {
      const {
        reattempts_disabled,
        reattempts
      } = values;

      let reattemptCount = 0;
      reattempts.forEach((reattemptDay) => {
        reattemptCount += reattemptDay.attempts.length;
      });

      onSave({
        ...data,
        reattempts_disabled: reattempts_disabled == true ? ((reattemptCount > 1) ? 0 : 1) : 1,
        reattempts,
      });
    },
  });

  const validateReattemtDay = (reattemptsArray) => {
    let errorsCount = 0;

    reattemptsArray.forEach((reattempt1, index1) => {
      let errors = false;

      reattemptsArray.forEach((reattempt2, index2) => {
        if (reattempt1?.day === reattempt2?.day && index1 !== index2) {
          errors = true;
          errorsCount += 1;

          formik.setFieldError(`reattempts[${index1}].day`, true);
          formik.setFieldError(`reattempts[${index2}].day`, true);
        }
      });

      if (reattempt1.day === null) {
        errorsCount += 1;

        formik.setFieldError(`reattempts[${index1}].day`, true);
      } else if (errors === false) {
        formik.setFieldError(`reattempts[${index1}]`, {});
      }
    });

    if (errorsCount < 1) {
      formik.validateForm();
    }
  };

  const recalculateReatteptsCounter = (reattemptsList) => {
    let length = 0;

    reattemptsList.forEach((reattempt) => {
      length += reattempt.attempts.length;
    });

    setReatteptsCounter(length);
  }

  const getAttemptNumber = (day, index) => {
    let length = 0;

    const reattemptsArray = JSON.parse(JSON.stringify(formik.values.reattempts));

    reattemptsArray.forEach((reattempt) => {
      if (reattempt.day < day) {
        length += reattempt.attempts.length;
      }
    });

    const numberAttempt = length + index + 1;

    switch (numberAttempt) {
      case 1:
        return `${numberAttempt}st`;
      case 2:
        return `${numberAttempt}nd`;
      case 3:
        return `${numberAttempt}rd`;
      default:
        return `${numberAttempt}th`;
    }
  }

  const hoursList = () => {
    const list = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= 23; i++) {
      list.push(i)
    }

    return list;
  };

  const minutesList = (indexDay, index) => {
    const list = [];
    const reattemptsArray = JSON.parse(JSON.stringify(formik.values.reattempts[indexDay].attempts));

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= 59; i++) {
      if (reattemptsArray[indexDay]?.day !== 0 || reattemptsArray[indexDay]?.attempts[index]?.hours !== 0 || i !== 0) {
        list.push(i)
      }
    }

    return list;
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangeReattemptsDisabled = (event, value) => {
    formik.setFieldValue('reattempts_disabled', !formik.values.reattempts_disabled, false);
  };

  const handleAddAttempt = (day) => {
    const formData = JSON.parse(JSON.stringify(formik.values.reattempts));

    if (day > 0) {
      formData[day].attempts.push({ hours: 0, minutes: 0 });
    } else {
      formData[day].attempts.push({ hours: '', minutes: '' });
    }

    formik.setFieldValue('reattempts', formData, false).then(() => {
      recalculateReatteptsCounter(formData);
    });
  };

  const handleDeleteAttempt = (day, index) => {
    const formData = JSON.parse(JSON.stringify(formik.values.reattempts));

    formData[day].attempts.splice(index, 1);

    formik.setFieldValue('reattempts', formData, true).then(() => {
      recalculateReatteptsCounter(formData);
    });
  };

  const handleDeleteDay = (day) => {
    const formData = JSON.parse(JSON.stringify(formik.values.reattempts));

    formData.splice(day, 1);

    formik.setFieldValue('reattempts', formData, false).then(() => {
      recalculateReatteptsCounter(formData);
    });

    validateReattemtDay(formData);
  };

  const sortByDay = (first, second) => {
    if (first.day < second.day || second.day === null) {
      return -1;
    }

    if (first.day > second.day) {
      return 1;
    }

    return 0;
  }

  const sortByAttept = (first, second) => {
    if (!first.hours && first.hours !== 0) {
      return 1;
    }
    if (!second.hours && second.hours !== 0) {
      return 0;
    }

    if (!first.minutes && first.minutes !== 0) {
      return 1;
    }

    if (!second.minutes && second.minutes !== 0) {
      return 0;
    }

    const sortParam = (first.hours * 60 + first.minutes) - (second.hours * 60 + second.minutes);

    if (sortParam > 0) {
      return 1;
    }

    if (sortParam < 0) {
      return -1;
    }

    return 0;
  }

  const handleAddDay = () => {
    const formData = JSON.parse(JSON.stringify(formik.values.reattempts));

    formData.push({ day: formData[formData.length - 1].day + 1, attempts: [] });

    formik.setFieldTouched(`reattempts`, true, false).then(() => {
      formik.setFieldValue('reattempts', formData, false);
      recalculateReatteptsCounter(formData);
      // formik.setFieldError(`reattempts[${formData.length > 0 ? formData.length - 1 : 0}].day`, true);
    })
    setTimeout(() => {
      setAddNewDay(true);
    }, 0);
  };

  const handleChangeDay = (indexDay, event) => {
    const { target } = event;
    const { value } = target;

    const reattemptsArray = JSON.parse(JSON.stringify(formik.values.reattempts));

    if (value > 1) {
      reattemptsArray[indexDay].day = value - 1;
    }
    reattemptsArray.sort(sortByDay);

    formik.setFieldTouched(`reattempts`, true, false)
      .then(() => {
        formik.setFieldValue('reattempts', reattemptsArray, false).then(() => {
          recalculateReatteptsCounter(reattemptsArray);
        });

        validateReattemtDay(reattemptsArray);
      });
  };

  const handleChangeTime = (indexDay, index, event) => {
    const { target } = event;
    const { name, value } = target;

    const attemptsArray = JSON.parse(JSON.stringify(formik.values.reattempts[indexDay].attempts));

    if (name.indexOf('.hrs') < 0) {
      attemptsArray[index].minutes = value;
    } else {
      attemptsArray[index].hours = value;
    }

    if ((attemptsArray[index].hours || attemptsArray[index].hours === 0) && (attemptsArray[index].minutes || attemptsArray[index].minutes === 0)) {
      attemptsArray.sort(sortByAttept);
    }

    formik.setFieldValue(`reattempts[${indexDay}].attempts`, attemptsArray, true);
  };

  useEffect(() => {
    if (advancedSettings.reattempts_limit > 0) {
      setReatteptsCounterLimit(advancedSettings.reattempts_limit);
    } else {
      setReatteptsCounterLimit(6);
    }
  }, [advancedSettings]);

  useEffect(() => {
    formik.setFieldValue('reattempts_disabled', data.reattempts_disabled == 0, false);
    formik.setFieldValue('reattempts', data.reattempts, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (addNewDay) {
      handleAddAttempt(formik.values.reattempts.length - 1);
    }
    setAddNewDay(false);
  }, [addNewDay]);

  return (
    <>
      <Grid container spacing={3} className={classes.spacer}>
        <Grid item md>
          <Typography variant="body2" className={classes.callAttemptsLabel}>
            <TooltipedText tooltipText="Set the duration between each lead call attempt">
              Call Attempt Intervals
            </TooltipedText>
          </Typography>

          <Fragment key={`first-call-${groupId}`}>
            <Grid className={classes.spacer3} container spacing={2} alignItems="center">
              <Grid item xs="auto">
                <Switch
                  // eslint-disable-next-line eqeqeq
                  checked={formik.values.reattempts_disabled}
                  onChange={handleChangeReattemptsDisabled}
                />
              </Grid>

              <Grid item xs style={{ display: 'flex', alignItems: 'center' }}>
                <span className={classes.reatepmtsIcon} />

                <Typography>Reattempts</Typography>
              </Grid>
            </Grid>
          </Fragment>

          {
            formik.values.reattempts.map((reattempt, indexDay) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`reattempts-timeouts-${groupId}-${indexDay}`}>
                <Grid
                  className={classes.dayWrap}
                  container
                  spacing={2}
                  style={
                    indexDay + 1 === formik.values.reattempts.length
                      ? { marginBottom: 0 }
                      : {}
                  }
                >
                  <Grid item xs="auto" className={classes.labelDayColumn}>
                    {
                      reattempt.day === 0
                        ? (<Typography align="center" className={classes.labelDay}>
                          Day {reattempt.day + 1}
                        </Typography>)
                        : (<TextField
                          id={`reattempts[${indexDay}].day`}
                          name={`reattempts[${indexDay}].day`}
                          label="Day"
                          value={formik.values.reattempts[indexDay].day + 1}
                          variant="outlined"
                          size="small"
                          color="secondary"
                          fullWidth
                          type="number"
                          inputProps={inputProps}
                          onChange={(event) => handleChangeDay(indexDay, event)}
                          focused={false}
                          error={
                            formik.touched.reattempts
                            && formik.errors.reattempts
                            && Boolean(formik.errors.reattempts[indexDay]?.day)
                          }
                        />)
                    }
                  </Grid>

                  <Grid item xs="auto" className={classes.attemptList}>
                    <Grid container>
                      {
                        reattempt.attempts?.map((timeout, index) => (
                          <Grid
                            key={index}
                            className={
                              (index !== (reattempt.attempts.length))
                                ? classes.attemptListWrap
                                : classes.attemptListWrapNoMargin
                            }
                            item
                            xs="auto"
                          >
                            <Grid
                              item
                              xs="auto"
                              align="center"
                              className={
                                reattempt.day === 0 && index === 0
                                  ? classes.labelFirst
                                  : classes.label
                              }
                            >

                              {(
                                (index < reattempt.attempts.length - 1) ||
                                ((index === reattempt.attempts.length - 1) && (reatteptsCounter < reatteptsCounterLimit))
                              ) && (<Grid item className={classes.labelDivider} />)}

                              <Grid item className={classes.labelAttempt}>
                                {getAttemptNumber(reattempt.day, index)} attempt
                              </Grid>

                              {
                                formik.values.reattempts[indexDay].day === 0 && index === 0
                                  ? (<></>)
                                  : (<Grid item>
                                    <ButtonBase onClick={() => handleDeleteAttempt(indexDay, index)}>
                                      <DeleteIcon />
                                    </ButtonBase>
                                  </Grid>)
                              }
                            </Grid>

                            {
                              // eslint-disable-next-line no-nested-ternary
                              indexDay < 1 && index > 0
                                ? (<Grid item xs="auto" align="center" className={classes.hover}>
                                  <TextField
                                    id={`reattempts[${indexDay}].attempts[${index}].hrs`}
                                    name={`reattempts[${indexDay}].attempts[${index}].hrs`}
                                    label="hrs"
                                    value={formik.values.reattempts[indexDay].attempts[index].hours}
                                    className={classes.hideRight}
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    fullWidth
                                    select
                                    onChange={(event) => handleChangeTime(indexDay, index, event)}
                                    onBlur={formik.handleBlur}
                                    focused={false}
                                    error={
                                      formik.touched.reattempts
                                      && formik.touched.reattempts[indexDay]?.attempts
                                      && (
                                        formik.touched.reattempts[indexDay].attempts[index]?.minutes
                                        || formik.touched.reattempts[indexDay].attempts[index]?.hours
                                      )
                                      && formik.errors.reattempts
                                      && formik.errors.reattempts[indexDay]?.attempts
                                      && (Boolean(
                                        formik.errors.reattempts[indexDay].attempts[index]?.minutes
                                        || formik.errors.reattempts[indexDay].attempts[index]?.hours
                                      ))
                                    }
                                  >
                                    {
                                      hoursList().map((value, indexHour) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <MenuItem key={`${indexDay}-${indexHour}`} value={value}>{value}</MenuItem>
                                      ))
                                    }
                                  </TextField>

                                  <TextField
                                    id={`reattempts[${indexDay}].attempts[${index}].minutes`}
                                    name={`reattempts[${indexDay}].attempts[${index}].minutes`}
                                    label="mins"
                                    value={formik.values.reattempts[indexDay].attempts[index].minutes}
                                    className={classes.hideLeft}
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    fullWidth
                                    select
                                    onChange={(event) => handleChangeTime(indexDay, index, event)}
                                    onBlur={formik.handleBlur}
                                    focused={false}
                                    error={
                                      formik.touched.reattempts
                                      && formik.touched.reattempts[indexDay]?.attempts
                                      && (
                                        formik.touched.reattempts[indexDay].attempts[index]?.minutes
                                        || formik.touched.reattempts[indexDay].attempts[index]?.hours
                                      )
                                      && formik.errors.reattempts
                                      && formik.errors.reattempts[indexDay]?.attempts
                                      && (Boolean(
                                        formik.errors.reattempts[indexDay].attempts[index]?.minutes
                                        || formik.errors.reattempts[indexDay].attempts[index]?.hours
                                      ))
                                    }
                                  >
                                    {
                                      minutesList(indexDay, index).map((value, indexMinute) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <MenuItem key={`${indexDay}-${indexMinute}`} value={value ?? ''}>{value}</MenuItem>
                                      ))
                                    }
                                  </TextField>
                                </Grid>)
                                : (
                                  indexDay === 0 && index === 0 && (<Grid item xs="auto" align="center" className={classes.labelTime}>
                                    {`${timeout.hours} hrs ${timeout.minutes} mins`}
                                  </Grid>)
                                )
                            }
                          </Grid>
                        ))
                      }

                      {
                        (reatteptsCounter < reatteptsCounterLimit) && (
                          <Grid color="primary" item xs="auto" className={classes.addIconPosition}>
                            {/* <Grid item className={classes.labelDividerLast} /> */}

                            <ButtonBase
                              className={classes.newWorkingTimeWrapper}
                              onClick={() => handleAddAttempt(indexDay)}
                              disableRipple
                            >
                              <div className={classes.addIconWrapper}>
                                <AddIcon className={classes.addIcon} />
                              </div>

                              <Typography className={classes.addIconLabel}>
                                Add attempt
                              </Typography>
                            </ButtonBase>
                          </Grid>
                        )
                      }
                    </Grid>

                    {
                      indexDay > 0 && (<Grid item xs="auto" align="center" className={classes.labelTooltip}>
                        <TooltipedText tooltipText="Please switch to Custom working hours mode to make changes">
                          <span className={classes.flexDirectoinReverse}>Adjust timeframe in Call schedule</span>
                        </TooltipedText>
                      </Grid>)
                    }
                  </Grid>

                  {
                    formik.values.reattempts[indexDay].day > 0 && (<Grid item className={classes.dayDelete}>
                      <ButtonBase onClick={() => handleDeleteDay(indexDay)}>
                        <DeleteIcon />
                      </ButtonBase>
                    </Grid>)
                  }
                </Grid>
              </Fragment>
            ))
          }
        </Grid>
      </Grid>

      {
        (reatteptsCounter < reatteptsCounterLimit) && (<Grid item container justifyContent="flex-start" spacing={2}>
          <ButtonBase
            className={classes.newWorkingTimeWrapper}
            onClick={() => handleAddDay()}
            disableRipple
          >
            <div className={classes.addIconWrapper}>
              <AddIcon className={classes.addIcon} />
            </div>

            <Typography className={classes.addIconLabel}>
              Add another day for more attempts
            </Typography>
          </ButtonBase>
        </Grid>)
      }

      <Grid container justifyContent="flex-end" spacing={3}>
        <Grid item>
          <Button color="primary" size="large" disableElevation onClick={onCancel}>
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            onClick={formik.submitForm}
            disabled={!formik.isValid}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
