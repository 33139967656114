/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { PhoneCountryDropdownByUser } from 'src/components/phoneInput';

import { useCountriesData, useFetchCountries } from '../state/hooks';

const useStyles = makeStyles(() => ({
  loadingContainer: {
    minHeight: '100%',
  },
}));

export const CountryDropdown = countryDropdownProps => {
  const classes = useStyles();
  const { data, isLoading } = useCountriesData();
  const { countriesList } = countryDropdownProps;

  useEffect(() => {
    countriesList(data);
  }, [countriesList, data]);

  return (
    <LoadingContainer classNames={classes.loadingContainer} loading={isLoading}>
      <PhoneCountryDropdownByUser
        {...countryDropdownProps}
        data={data}
      />
    </LoadingContainer>
  );
};

export default CountryDropdown;