import React, { useEffect } from 'react';

import checkRole from 'src/helpers/aclHelper';

import CreateGroup from './createGroup';
import { GroupListContainer } from './groupList/GroupListContainer';

import { useFetchGroups, useGroupsData } from './state/hooks';

export default function () {
  const { data, isLoading } = useGroupsData();

  useFetchGroups();

  useEffect(() => {
  }, [data]);

  return (
    <>
      {checkRole(['admin', 'manager']) &&
        (<CreateGroup data={data} isLoading={isLoading} />)
      }

      <GroupListContainer data={data} isLoading={isLoading} />
    </>
  );
}
