import React, { useState } from 'react';
import { Divider, makeStyles } from '@material-ui/core';

import EditConcurentCall from './edit';
import ViewConcurentCall from './view';
import { useGroupActions } from '../../state/hooks';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(5, 0),
  },
}));

export default function Group({ groupId, data, isEditAllowed }) {
  const classes = useStyles();

  const [edit, setEdit] = useState(false);

  const { updateGroup, cancelGroupCallAttempts } = useGroupActions();

  const handleEdit = () => {
    setEdit(true);
  };

  const handleSave = group => {
    updateGroup(group);

    setEdit(false);
  };

  const handleCancel = () => {
    cancelGroupCallAttempts();

    setEdit(false);
  };

  return (
    <div>
      {
        edit
          ? (<EditConcurentCall groupId={groupId} data={data} onSave={handleSave} onCancel={handleCancel} />)
          : (<ViewConcurentCall groupId={groupId} data={data} onEdit={handleEdit} isEditAllowed={isEditAllowed} />)
      }

      <Divider className={classes.divider} />
    </div>
  );
}
