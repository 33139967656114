import React from 'react';
import clsx from 'clsx';
import { colors, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid',
    padding: theme.spacing(0, 1),
    width: 78,
  },
  enabled: {
    borderColor: '#39CC93',
    color: '#39CC93',
  },
  disabled: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
  },
  fontBold: {
    fontWeight: 'bold',
  }
}));

export default function SwitchTag({ checked }) {
  const classes = useStyles();

  return !checked ? (
    <div className={clsx(classes.root, classes.enabled)}>
      <Typography variant="subtitle2" className={classes.fontBold}>Enabled</Typography>
    </div>
  ) : (
    <div className={clsx(classes.root, classes.disabled)}>
      <Typography variant="subtitle2" className={classes.fontBold}>Disabled</Typography>
    </div>
  );
}
