/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import queryString from 'query-string';

import { GroupListItem } from './GroupListItem';

import { useFetchTimezones } from '../../state/hooks';

export const GroupList = ({ data }) => {
  useFetchTimezones();

  const anchor = queryString.parse(location.search) && queryString.parse(location.search).key ? queryString.parse(location.search).key : false;

  const groupList = data.map((item, index) => {
    return <GroupListItem key={`group-item-wrapper-${index}`}
      groupId={item.id}
      data={item}
      loading={item.loading}
      isOpen={(data.length === 1 && !!anchor)}
      anchor={anchor} />
  });

  return groupList;
};
