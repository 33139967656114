/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Dialog,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  Typography,
  Grid,
  Button,
  FormControl,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { CustomPaper } from 'src/components/autocomplete';
import TooltipedText from 'src/components/tooltipedText';
import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { GroupLocationsInputLabelRow } from './GroupLocationsInputLabelRow';

import { useLocationsData } from '../../state/hooks';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(5),
    width: '100%',
  },
  row: {
    backgroundColor: '#F6F6F6',
    padding: theme.spacing(3),
    borderRadius: 4,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  loadingContainer: {
    marginBottom: theme.spacing(2),
  },
  listbox: {
    maxHeight: 'unset',
  },
}));

export const GroupLocationsInput = ({
  data,
  label,
  name,
  value,
  error,
  onChange,
  onBlur,
  onCreateLocation,
  onRemoveLocation,
  onUpdateLocation,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const { isLoading } = useLocationsData();

  const formik = useFormik({
    initialValues: {
      id: 100,
      color: '#7BDCB5',
      label: '',
    },
    validationSchema: yup.object().shape({
      color: yup.string().required(),
      label: yup.string().required(),
    }),
    onSubmit: (values, actions) => {
      actions.resetForm();
      actions.setSubmitting(false);

      onCreateLocation({ ...values });
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleEdit = location => {
    onUpdateLocation(location);
  };

  const handleDelete = ({ id }) => () => {
    onRemoveLocation(id);
  };

  const handleSelect = (event, val) => {
    onChange(name, val.id, false);
  };

  const getValue = () => {
    return data.filter(ele => ele.id === value).pop() ?? '';
  };

  useEffect(() => {
    formik.resetForm();
  }, [open]);

  return (
    <>
      <Autocomplete
        classes={{ listbox: classes.listbox }}
        options={data}
        getOptionLabel={option => option.label ?? 'No rule'}
        disableClearable
        PaperComponent={CustomPaper}
        onChange={handleSelect}
        value={getValue()}
        renderOption={option => (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography>{option.label}</Typography>
            </Grid>
          </Grid>
        )}
        renderInput={params => (
          <TextField
            {...params}
            size="small"
            label={label}
            variant="outlined"
            color="secondary"
            name={name}
            onBlur={onBlur}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <IconButton color="primary" size="small" onClick={handleOpen}>
                      <EditIcon />
                    </IconButton>
                  </InputAdornment>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      <Dialog onClose={handleClose} open={open} fullWidth>
        <Paper className={classes.paper}>
          <TooltipedText tooltipText="Option to route leads to specific Groups based on your criteria" gutterBottom={3}>
            <Typography variant="h6">Routing rule parameters</Typography>
          </TooltipedText>

          <LoadingContainer classNames={classes.loadingContainer} loading={isLoading}>
            {data.map((location, idx) => (
              location?.id !== null && (
                <GroupLocationsInputLabelRow
                  key={idx}
                  {...location}
                  onEdit={handleEdit}
                  onDelete={handleDelete(location)}
                />
              )
            ))}
          </LoadingContainer>

          <div className={classes.row}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <TextField
                  placeholder="New routing rule parameter"
                  size="small"
                  variant="outlined"
                  color="secondary"
                  name="label"
                  fullWidth
                  value={formik.values.label}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.label && Boolean(formik.errors.label)}
                />
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  disabled={!formik.isValid || formik.isSubmitting}
                  onClick={formik.handleSubmit}
                >
                  Add
                </Button>
              </Grid>
            </Grid>

            <FormControl margin="dense">
              <Typography>Parameters can be based on lead location, lead source, product type, etc.</Typography>
            </FormControl>
          </div>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                onClick={handleClose}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};
