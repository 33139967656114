/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  Box,
  Typography,
  RadioGroup,
} from '@material-ui/core';
import { addMinutes, format, isWithinInterval, eachMinuteOfInterval } from 'date-fns';
import {
  convertTimeToInterval,
  getWeekDay,
} from 'src/helpers/time';

import Checkbox from 'src/components/checkbox';
import WorkTime from './WorkTime';
import ScheduleLeadType from './ScheduleLeadType';

const useStyles = makeStyles(theme => ({
  buttonsWrapper: {
    marginTop: '7px',
  },
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: 15,
    padding: '35px 40px',
  },
  keyMap: {
    display: 'flex',
    flexDirection: 'row',
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    marginRight: 12,
  },
  label: {
    fontSize: 13,
    marginRight: 5,
  },
  //   day: {
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     border: '1px solid #202239',
  //     backgroundColor: '#3C3D52',
  //     width: 65,
  //     height: 35,
  //     color: '#FFFFFF',
  //     marginRight: theme.spacing(5),
  //   },
  scheduleView: {
    width: 'calc(100% - 32px)',
    '& > * > *:not(:last-child)': {
      paddingBottom: theme.spacing(12),
    },
  },
  scheduleEdit: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2.5),
    },
  },
  dayWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid #E0DEDE',
  },
  hoursWrapper: {
    width: '100%',
    position: 'relative',
    borderRight: '1px solid #E0DEDE',
  },
  tableContainer: {
    flexGrow: 1,
    overflowX: 'auto',
    // marginLeft: theme.spacing(0.5),
    '& .MuiTableCell-head': {
      paddingLeft: 0,
      textAlign: 'left',
    },
    '& .MuiTableCell-root': {
      borderBottom: 'unset',
      position: 'relative',
      padding: 0,
    },
    '& .MuiTableCell-body': {
      position: 'relative',
    },
    '& th': {
      paddingTop: 0,
    },
    '& .MuiTableBody-root .MuiTableRow-root > *': {
      padding: 0,
      paddingBottom: 1.25,
    },
    '& .MuiTableBody-root .MuiTableRow-root:first-child > *': {
      paddingTop: 2.5,
    },
    '& .MuiTableBody-root .MuiTableRow-root:last-child > *': {
      paddingBottom: 0,
      margin: 0,
    },
    '& .MuiTableRow-root .MuiTableCell-root:last-child > *': {
      // padding: 20,
    },
  },
  tableRow: {
    display: 'flex',
  },
  firstWorkingTimeCell: {
    height: 25,
    width: '50%',
    top: 0,
    backgroundColor: 'white',
    position: 'absolute',
  },
  day: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #202239',
    backgroundColor: '#3C3D52',
    width: 65,
    minWidth: 65,
    height: 35,
    color: '#FFFFFF',
    marginRight: ({ mode }) => (mode === 'view' ? theme.spacing(5) : theme.spacing(1)),
  },
  dayName: {
    textTransform: 'capitalize',
  },
  dayPlanning: {
    display: 'flex',
    alignItems: 'baseline',
    backgroundColor: '#F8F8F8',
    padding: theme.spacing(1.2, 2.2),
    paddingBottom: theme.spacing(2),
    border: '1px solid #EBEBEB',
    borderRadius: 5,
  },
  radioGroup: {
    '& > .MuiFormControlLabel-root': {
      marginRight: theme.spacing(5),
    },
  },
  leadTypesContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2.5),
    paddingLeft: theme.spacing(1.8),
  },
  leadTypesTitle: {
    fontSize: 15,
    color: '#3C3D52',
  },
  leadTypesWrapper: {
    display: 'flex',
    paddingTop: theme.spacing(3),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  allSelect: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  allSelectCheckbox: {
    paddingTop: 7,
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    width: '10.5px',
    height: '20px',
  },
  tableCellHeader: {
    display: 'block',
    position: 'relative',
    height: '40px',
    width: '10.5px',
  },
  tableCellHeaderSpan: {
    width: '30px',
    position: 'absolute',
    top: 0,
    left: 0,
    lineHeight: '18px',
  }
}));

const scheduleLeadTypeColors = {
  fresh: {
    background: '#FEF077',
    bottom: '75px',
  },
  after_hours: {
    background: '#2A5068',
    bottom: '55px',
  },
  reattempts: {
    background: '#98C1D3',
    bottom: '35px',
  },
};

const workingTypeColors = { 1: 'transparent', 2: '#F6F6F6', 3: 'transparent' };

const workingTypeData = {
  1: { color: '#transparent', label: 'Open all day' },
  2: { color: '#F6F6F6', label: 'Closed all day' },
  3: { color: '#transparent', label: 'Custom working hours' },
};

export default function ScheduleRow({
  mode,
  groupId,
  workingDay,
  dayScheduleData,
  workingTimeInterval,
  onWorkingTypeChange,
  onWorkingTimeChange,
  onScheduleChange,
  allSelected,
  setAllSelected,
  handleCancelButton,
  handleSaveButton,
  disabledButton,
  showButton
}) {
  const classes = useStyles({ mode });

  const [isChanged, setIsChanged] = useState(false);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [open, setOpen] = useState(false);

  const [eventName, setEventName] = useState(false);

  const [dayType, setDayType] = useState(false);

  const [openTimeDialog, setOpenTimeDialog] = useState(false);


  const formik = useFormik({
    initialValues: {
      workingTimes: [],
      scheduleLeadTypes: [],
      type: 1,
    },
  });

  const [intervalsByHours, setIntervalsByHours] = useState([]);

  const handleChangeDayType = (eventName, type) => {
    const dateByDay = getWeekDay(workingDay);

    const isOpen = (type === '2' ? 0 : 1);

    const scheduleDefault = {
      fresh: [],
      after_hours: [],
      reattempts: [],
    };

    let workTimeDefault = [];

    switch (type) {
      case '1':
        scheduleDefault.fresh = convertTimeToInterval(dateByDay, [{ from: '0:00', to: '24:00' }]);
        scheduleDefault.after_hours = convertTimeToInterval(dateByDay, [{ from: '0:00', to: '24:00' }]);
        scheduleDefault.reattempts = convertTimeToInterval(dateByDay, [{ from: '0:00', to: '24:00' }]);

        break;
      case '2':
        break;
      case '3':
        scheduleDefault.fresh = convertTimeToInterval(dateByDay, [{ from: '8:00', to: '18:00' }]);
        scheduleDefault.after_hours = convertTimeToInterval(dateByDay, [{ from: '8:00', to: '18:00' }]);
        scheduleDefault.reattempts = convertTimeToInterval(dateByDay, [{ from: '8:00', to: '18:00' }]);

        workTimeDefault = convertTimeToInterval(dateByDay, [{ from: '08:00', to: '18:00' }])

        break;
      default:
        break;
    }

    onWorkingTypeChange(type, workingDay, isOpen);

    formik.setFieldValue('workingTimes', workTimeDefault, false);
    formik.setFieldValue('scheduleLeadTypes', scheduleDefault, false);
    formik.setFieldValue(eventName, type, false);

    setOpen(false);
    setOpenTimeDialog(false);
    setIsChanged(true);
  };

  const handleCloseTimeDialog = () => {
    setOpenTimeDialog(false);
  };

  const handleApplyTimeDialog = () => {
    handleChangeDayType(eventName, dayType);
  };

  const handleClickDayType = (eventData, type) => {
    setEventName(JSON.parse(JSON.stringify(eventData.target.name)));
    setDayType(type);

    if (formik.values.type == '2') {
      if (type == '1') {
        setOpenTimeDialog(true);
        return;
      }

      handleChangeDayType(eventData.target.name, type);

      return;
    }

    if (type == '1') {
      setOpenTimeDialog(true);
      return;
    }

    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleApplyDialog = () => {
    if (dayType == '1') {
      setOpenTimeDialog(true);
      setOpen(false);
      return;
    }

    handleChangeDayType(eventName, dayType);
  };

  const handleChangeSchedule = (name, value) => {

    onScheduleChange(workingDay, name, value);

    formik.setFieldValue(
      'scheduleLeadTypes',
      {
        ...formik.values.scheduleLeadTypes,
        [name]: value,
      },
      false,
    );

    if (!isFirstLoad) {
      setIsChanged(true);
    }
  };

  const handleChangeWorkingTime = (name, value, isFirst) => {
    onWorkingTimeChange(workingDay, value);

    const workingIntervals = JSON.parse(JSON.stringify(formik.values.workingTimes));

    const scheduleLeads = JSON.parse(JSON.stringify(formik.values.scheduleLeadTypes));

    const scheduleLeadsNew = {};

    if (Object.keys(scheduleLeads).length < 1 || workingIntervals.length < 1) {
      return;
    }

    Object.entries(scheduleLeads).forEach(([leadType, scheduleIntervals]) => {
      if (workingIntervals.length < 1) {
        return;
      }

      if (!scheduleLeadsNew[leadType]) {
        scheduleLeadsNew[leadType] = [];
      }

      scheduleLeadsNew[leadType].push({
        start: new Date(workingIntervals[0].start).toString(),
        end: new Date(workingIntervals[0].end).toString()
      });

      handleChangeSchedule(leadType, scheduleLeadsNew[leadType]);
    });

    formik.setValues({
      workingTimes: value,
      scheduleLeadTypes: scheduleLeadsNew,
      type: formik.values.type,
    });

    if (!isFirst) {
      setIsChanged(true);
    } else {
      setIsFirstLoad(false);
    }
  };

  const handleCheckAllSelected = (name, value) => {
    if (!value) {
      setAllSelected(null);
    } else {
      setAllSelected(workingDay);
    }

    setIsChanged(true);
  };

  const clickCancelButton = () => {
    handleCancelButton();
    setIsChanged(false);
  }

  const clickSaveButton = () => {
    handleSaveButton();
    setIsChanged(false);
  }

  useEffect(() => {
    setIsChanged(false);
  }, [mode]);

  useEffect(() => {
    if (mode === 'view') {
      formik.setValues(dayScheduleData);
    }

  }, [mode, dayScheduleData]);

  useEffect(() => {
    const intervalsByHoursShort = eachMinuteOfInterval(workingTimeInterval, { step: 60 });
    intervalsByHoursShort.pop();
    setIntervalsByHours(intervalsByHoursShort);
  }, [workingTimeInterval]);

  return mode === 'view'
    ? (
      <div className={classes.scheduleView}>
        <Box display="flex">
          <div className={classes.dayWrapper}>
            <div className={classes.day}>
              <Typography className={classes.dayName} variant="body2">
                {workingDay}
              </Typography>
            </div>
          </div>

          <div className={classes.hoursWrapper}>
            {
              intervalsByHours.map((workingDate, index1) => (
                <div
                  key={`${groupId}-${workingDay}-${index1}-intervalsByHours`}
                  className={classes.tableCellHeader}
                  style={{
                    fontSize: 12,
                    position: 'absolute',
                    width: `calc(100% / ${intervalsByHours.length})`,
                    left: `calc(${index1} * (100% / ${intervalsByHours.length}))`
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      width: workingDate.getMinutes() === 0 ? '25px' : '0',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                        fontWeight: 400,
                        textAlign: 'center',
                      }}
                    >
                      {workingDate.getMinutes() === 0 ? format(workingDate, 'h aa') : null}
                    </div>
                  </div>
                </div>
              ))
            }

            {
              formik.values && Object.keys(formik.values.workingTimes).length < 1
                ? (
                  <div
                    style={{
                      background: workingTypeColors[formik.values.type],
                      position: 'absolute',
                      width: 'calc(100%)',
                      height: '52px',
                      bottom: '35px',
                      left: '0px',

                    }}
                  />
                )
                : formik.values.workingTimes.map((workingTime, index) => (
                  <div
                    key={`${groupId}-${workingDay}-${index}-workingTimes`}
                    style={{
                      background: workingTypeColors[formik.values.type],
                      position: 'absolute',
                      height: '52px',
                      bottom: '35px',
                      width: `calc(
                              ${formik.values.workingTimes.length < 1
                          ? 24
                          : (new Date(workingTime.end).valueOf() - new Date(workingTime.start).valueOf()) / 3600000
                        } / 24 * 100%
                          )`,
                      left: `calc(
                            ${formik.values.workingTimes.length < 1
                          ? 0
                          : (new Date(workingTime.start)).getHours() + (new Date(workingTime.start)).getMinutes() / 60
                        } / 24 * 100%
                          )`,
                    }}
                  ></div>
                ))
            }

            {
              formik.values &&
              Object.keys(formik.values.scheduleLeadTypes).map((type, indexX) => (
                formik.values.scheduleLeadTypes[type].map((scheduleLead, indexY) => (
                  <div
                    key={`${groupId}-${workingDay}-${indexX}-${indexY}`}
                    style={{
                      position: 'absolute',
                      height: '12px',
                      background: scheduleLeadTypeColors[type]?.background,
                      bottom: scheduleLeadTypeColors[type]?.bottom,
                      width: `calc(
                            ${formik.values.type == 1
                          ? 24
                          : (new Date(scheduleLead.end).valueOf() - new Date(scheduleLead.start).valueOf()) / 3600000
                        } / 24 * 100%
                          )`,
                      left: `calc(
                            ${(new Date(scheduleLead.start)).getHours() + (new Date(scheduleLead.start)).getMinutes() / 60} / 24 * 100%
                          )`,
                    }}
                  ></div>
                ))))
            }
          </div>
        </Box>
      </div>
    )
    : (
      <div className={classes.scheduleEdit}>
        <div
          className={classes.dayPlanning}
          style={
            formik.values.type && formik.values.type !== '3'
              ? { backgroundColor: workingTypeData[formik.values.type].color }
              : {}
          }
        >
          <div className={classes.day}>
            <Typography className={classes.dayName} variant="body2">
              {workingDay}
            </Typography>
          </div>

          <Box flexGrow={1}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div className={classes.allSelect} style={{maxWidth: '505px'}}>
                <RadioGroup
                  id="type"
                  name="type"
                  className={classes.radioGroup}
                  value={formik.values.type}
                  onChange={handleClickDayType}
                  onBlur={formik.handleBlur}
                  row
                >
                  {
                    Object.keys(workingTypeData).map(workingType => (
                      <WorkTime
                        id="workingTimes"
                        name="workingTimes"
                        mode={mode}
                        key={`${groupId}-${workingDay}-${workingType}-workingTypeData`}
                        value={formik.values.workingTimes}
                        type={formik.values.type}
                        onChange={handleChangeWorkingTime}
                        workingTimeInterval={workingTimeInterval}
                        workingType={workingType}
                        day={workingDay}
                      />
                    ))
                  }
                </RadioGroup>
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end', flexDirection: 'column'}}>
                {
                  (((allSelected && allSelected === workingDay) || (!allSelected)) && dayScheduleData.type !== '2') && (
                    <div className={classes.allSelectCheckbox}>
                      <Checkbox
                        label="Apply this schedule to all days open"
                        id="allDays"
                        checked={allSelected === workingDay}
                        onChange={handleCheckAllSelected}
                      // tooltipText={prismicData.tooltip1b.text}
                      />
                    </div>
                  )
                }

                {
                  (isChanged && mode !== 'view')
                  ? (<div className={classes.buttonsWrapper}>
                      <Button color="primary" size="large" disableElevation onClick={clickCancelButton}>
                        Cancel
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disableElevation
                        disabled={disabledButton}
                        onClick={clickSaveButton}
                      >
                        Save
                      </Button>
                    </div>)
                  : (<></>)
                }
              </div>
            </div>

            {(formik.values.type === '1' ||
              (formik.values.type === '3' && Boolean(formik.values.workingTimes.length))) && (
                <div className={classes.leadTypesContainer}>
                  <Typography variant="subtitle1" className={classes.leadTypesTitle}>
                    Schedule by Lead Call Type
                  </Typography>

                  <div className={classes.leadTypesWrapper}>
                    {Object.keys(formik.values.scheduleLeadTypes).map(scheduleLeadType => (
                      <ScheduleLeadType
                        key={`${groupId}-${workingDay}_${scheduleLeadType}`}
                        day={workingDay}
                        name={scheduleLeadType}
                        value={formik.values.scheduleLeadTypes[scheduleLeadType]}
                        type={formik.values.type}
                        workingTimes={formik.values.workingTimes}
                        onChange={handleChangeSchedule}
                        scheduleLeadType={scheduleLeadType}
                        workingTimeInterval={workingTimeInterval}
                      />
                    ))}
                  </div>
                </div>
              )}
          </Box>
        </div>

        <Dialog
          open={open}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">
              Use Google's location service?
            </DialogTitle> */}

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              If you switch work type you will lose your schedulers settings.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              color="primary"
              size="large"
              disableElevation
              onClick={handleCloseDialog}
            >
              Disagree
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              onClick={handleApplyDialog}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openTimeDialog}
          onClose={handleCloseTimeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to select a non-working time?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              color="primary"
              size="large"
              disableElevation
              onClick={handleCloseTimeDialog}
            >
              No
            </Button>

            <Button
              variant="contained"
              color="secondary"
              size="large"
              disableElevation
              onClick={handleApplyTimeDialog}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}
