/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  Typography,
  Select,
  MenuItem,
  ButtonBase,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { format, addMinutes, subMinutes, isEqual, isBefore } from 'date-fns';
import eachMinuteOfInterval from 'date-fns/eachMinuteOfInterval';
import clsx from 'clsx';

const workingTypeData = {
  1: { color: '#F3EFD3', label: 'Open all day' },
  2: { color: '#B5B6BD', label: 'Closed all day' },
  3: { color: '#E8E8E8', label: 'Working hours' },
};

const useStyles = makeStyles(theme => ({
  scheduleEdit: {
    margin: theme.spacing(5, 0),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2.5),
    },
  },
  day: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #202239',
    backgroundColor: '#3C3D52',
    width: 65,
    minWidth: 65,
    height: 35,
    color: '#FFFFFF',
    marginRight: theme.spacing(1),
  },
  dayPlanning: {
    display: 'flex',
    alignItems: 'baseline',
    backgroundColor: '#F8F8F8',
    padding: theme.spacing(1.2, 2.2),
    paddingBottom: theme.spacing(2),
    border: '1px solid #EBEBEB',
    borderRadius: 5,
  },
  radioGroup: {
    '& > .MuiFormControlLabel-root': {
      marginRight: theme.spacing(5),
    },
  },
  radioWrapper: {
    borderRadius: 5,
    opacity: 0.8,
    padding: theme.spacing(2, 1.5),
    '& > .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: theme.spacing(1.5),
    },
    '& > .MuiFormControlLabel-root > .MuiIconButton-root': {
      padding: 0,
      paddingRight: theme.spacing(1.5),
    },
  },
  workingRangesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    padding: theme.spacing(1.5, 4.75),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(0.5),
    },
  },
  workingRangesSelectsWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
    '& > .MuiOutlinedInput-root': {
      width: 114,
      height: 42,
    },
  },
  disabledNewWorkingTime: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  rangeLine: {
    width: 12,
    border: '1px solid #3C3D52',
  },
  newWorkingTimeWrapper: {
    display: 'flex',
    width: 'fit-content',
    marginTop: theme.spacing(1.75),
    cursor: 'pointer',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(0.6),
    },
    // '&:hover': {
    //   '&>.MuiTypography-root': {
    //     borderBottom: '1px solid',
    //     borderBottomColor: theme.palette.primary.main,
    //   },
    // },
  },
  addIconWrapper: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    width: 20,
    height: 20,
    position: 'relative',
  },
  addIcon: {
    fill: 'white',
    stroke: 'white',
    width: 11,
    height: 11,
    strokeWidth: 7,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  addIconLabel: {
    color: theme.palette.primary.main,
  },
  deleteIconWrapper: {
    height: 24,
    cursor: 'pointer',
  },
  deleteIcon: {
    fill: 'white',
  },
  leadTypesContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2.5),
    paddingLeft: theme.spacing(1.8),
  },
  leadTypesTitle: {
    fontSize: 15,
    color: '#3C3D52',
  },
  leadTypesWrapper: {
    display: 'flex',
    paddingTop: theme.spacing(3),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  leadType: {
    borderRadius: 5,
    flex: 1,
    padding: theme.spacing(2, 1.25),
    paddingLeft: theme.spacing(4),
  },
  label: {
    fontSize: 13,
    // paddingBottom: theme.spacing(1.75),
  },
}));

export default function WorkTime({ mode, value, workingTimeInterval, workingType, day, type, onChange, name }) {
  const classes = useStyles();
  const morningLimit = '08:00:00';
  const eveningLimit = '21:00:00';

  const [data, setData] = useState(value);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [index, setIndex] = useState(value);
  const [openTimeDialog, setOpenTimeDialog] = useState(false);

  const getStartWorkingTimeHours = index => {
    let lastStartWorkingTime;

    if (data.length > 1 && index !== 0) {
      lastStartWorkingTime = data[index - 1].end;
    } else {
      lastStartWorkingTime = workingTimeInterval.start;
    }

    if (isEqual(lastStartWorkingTime, subMinutes(workingTimeInterval.end, 15))) {
      return [lastStartWorkingTime];
    }

    if (isBefore(lastStartWorkingTime, workingTimeInterval.end)) {
      const eachMinutes = eachMinuteOfInterval(
        {
          start: lastStartWorkingTime,
          end: subMinutes(workingTimeInterval.end, 15),
        },
        { step: 15 },
      );

      return eachMinutes;
    }

    return [];
  };

  // **** BEGIN: Add new work time interval function ****
  // const addNewWorkingTime = () => {
  //   if (!data.length) {
  //     setData([{ start: false, end: false }]);
  //   } else {
  //     const lastWorkingTime = data[data.length - 1];
  //     if (lastWorkingTime.start && lastWorkingTime.end) {
  //       setData([...data, { start: false, end: false }]);
  //     }
  //   }
  // };
  // **** END: Add new work time interval function ****

  const deleteWorkingTime = index => {
    const newData = data;
    newData.splice(index, 1);
    setData([...newData]);
  };

  const handleCloseTimeDialog = () => {
    setOpenTimeDialog(false);
  };

  const handleApplyTimeDialog = () => {
    let newData = data;

    if (startTime) {
      if (!newData.length) {
        newData = [];
        newData.push({ start: startTime, end: false });
      } else {
        newData[index].start = startTime;
      }
    } else if (endTime) {
      newData[index].end = endTime;
    } else {
      setOpenTimeDialog(false);
      return;
    }

    setData([...newData]);
    setStartTime(null);
    setEndTime(null);
    setOpenTimeDialog(false);
  };

  const handleSelectStartWorkingTime = (event, workDay, index) => {
    const startDate = new Date(event.target.value);
    const startDateToTime = format(startDate, 'HH:mm:ss');

    if (startDateToTime < morningLimit || startDateToTime > eveningLimit) {
      setStartTime(startDate);
      setIndex(index);
      setOpenTimeDialog(true);
      return;
    }

    let newData = data;

    if (!newData.length) {
      newData = [];
      newData.push({ start: startDate, end: false });
    } else {
      newData[index].start = startDate;
    }

    setData([...newData]);
  };

  const handleSelectEndWorkingTime = (event, index) => {
    const endDate = new Date(event.target.value);
    const endDateToTime = format(endDate, 'HH:mm:ss');

    if (endDateToTime < morningLimit || endDateToTime > eveningLimit) {
      setEndTime(endDate);
      setIndex(index);
      setOpenTimeDialog(true);
      return;
    }

    const newData = data;
    newData[index].end = endDate;
    setData([...newData]);
  };

  // **** BEGIN: Check addition time availability ****
  // const checkAdditionTimeAvailability = () => {
  //   if (!data) {
  //     return true;
  //   }
  //   if (data.length > 0 && isEqual(data[data.length - 1].end, workingTimeInterval.end)) {
  //     return false;
  //   }
  //   if (data.length < 3) {
  //     return true;
  //   }
  //   return false;
  // };
  // **** END: Check addition time availability ****

  const getEndWorkingTimes = workingTime => {
    if (!isEqual(addMinutes(workingTime.start, 15), workingTimeInterval.end)) {
      const eachMinutes = eachMinuteOfInterval(
        {
          start: addMinutes(workingTime.start, 15),
          end: workingTimeInterval.end,
        },
        { step: 15 },
      );

      return eachMinutes;

    }

    return [workingTimeInterval.end];
  };

  useEffect(() => {
    onChange(name, data, isFirstLoad);
    setIsFirstLoad(false);
  }, [data]);

  useEffect(() => {
    setData(value);
  }, [value]);

  return (
    <div
      className={classes.radioWrapper}
      style={{
        backgroundColor: workingType === '3' && workingType === type
          ? workingTypeData[3].color
          : 'inherit',
      }}
    >
      <FormControlLabel
        value={workingType}
        control={<Radio color="secondary" />}
        label={workingTypeData[workingType].label}
      />

      {workingType === '3' && workingType === type && (
        <div className={classes.workingRangesWrapper}>
          {
            data.map((workingTime, index) => index < 1
              ? (<div key={`${day}_${index}`} className={classes.workingRangesSelectsWrapper}>
                <Select
                  value={
                    getStartWorkingTimeHours(index)[0] <= workingTime.start || getStartWorkingTimeHours(index)[getStartWorkingTimeHours(index).length - 1] >= workingTime.start
                      ? workingTime.start
                      : ''
                  }
                  variant="outlined"
                  color="secondary"
                  onChange={event => handleSelectStartWorkingTime(event, day, index)}
                >
                  {getStartWorkingTimeHours(index).map(workingDayTime => (
                    <MenuItem key={workingDayTime} value={workingDayTime.toString()}>
                      {format(workingDayTime, 'h:mm aa')}
                    </MenuItem>
                  ))}
                </Select>

                <div className={classes.rangeLine} />

                <Select
                  value={
                    getStartWorkingTimeHours(index)[0] <= workingTime.end || getStartWorkingTimeHours(index)[getStartWorkingTimeHours(index).length - 1] >= workingTime.end
                      ? workingTime.end
                      : ''
                  }
                  variant="outlined"
                  color="secondary"
                  onChange={event => handleSelectEndWorkingTime(event, index)}
                  disabled={!workingTime.start}
                >
                  {workingTime.start &&
                    getEndWorkingTimes(workingTime).map(workingDayTime => (
                      <MenuItem key={workingDayTime} value={workingDayTime.toString()}>
                        {format(workingDayTime, 'h:mm aa')}
                      </MenuItem>
                    ))}
                </Select>

                {index !== 0 && (
                  <div className={classes.deleteIconWrapper} onClick={() => deleteWorkingTime(index)}>
                    <CloseIcon className={classes.deleteIcon} fontSize="medium" />
                  </div>
                )}
              </div>)
              : (null)
            )
          }
          {/* **** BEGIN: Add new work time interval**** */}
          {/* {checkAdditionTimeAvailability() && (
            <ButtonBase className={classes.newWorkingTimeWrapper} onClick={addNewWorkingTime} disableRipple>
              <div className={classes.addIconWrapper}>
                <AddIcon className={classes.addIcon} />
              </div>

              <Typography className={clsx(classes.label, classes.addIconLabel)}>Add split schedule</Typography>
            </ButtonBase>
          )} */}
          {/* **** END: Add new work time interval**** */}
        </div>
      )}

      <Dialog
        open={openTimeDialog}
        onClose={handleCloseTimeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to select a non-working time?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            size="large"
            disableElevation
            onClick={handleCloseTimeDialog}
          >
            No
          </Button>

          <Button
            variant="contained"
            color="secondary"
            size="large"
            disableElevation
            onClick={handleApplyTimeDialog}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
