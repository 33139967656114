/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { makeStyles } from '@material-ui/core';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import RepLabelsTabs from './RepLabelsInput';

import { useRepLabelsActions, useRepLabelsData, useFetchRepLabels } from '../state/hooks';

const useStyles = makeStyles(() => ({
  loadingContainer: {
    minHeight: '100%',
  },
}));

export const RepLabels = repLabelsProps => {
  const classes = useStyles();

  const { data, isLoading } = useRepLabelsData();

  const { createRepLabel, removeRepLabel, updateRepLabel } = useRepLabelsActions();



  return (
    <LoadingContainer classNames={classes.loadingContainer} loading={isLoading}>
      <RepLabelsTabs
        {...repLabelsProps}
        data={data}
        onCreateLabel={createRepLabel}
        onRemoveLabel={removeRepLabel}
        onUpdateLabel={updateRepLabel}
      />
    </LoadingContainer>
  );
};
