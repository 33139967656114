/* eslint-disable camelcase */
/* eslint-disable no-empty-pattern */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Grid, Button, TextField, MenuItem, Box } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import TooltipedText from 'src/components/tooltipedText';

import {
  getStartOfWeekWithShiftedHours,
  setDayToInterval,
  convertTimeToInterval,
  convertIntervalToTime,
  getWeekDay,
} from 'src/helpers/time';
import ScheduleRow from './ScheduleRow';

import { useGroupActions, useTimezonesData } from '../../state/hooks';

const timeInterval = {
  start: getStartOfWeekWithShiftedHours(0),
  end: getStartOfWeekWithShiftedHours(24),
};

const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const scheduleTypes = ['fresh', 'after_hours', 'reattempts'];

const keyMaps = [
  // { bgColor: '#E8E8E8', label: 'Working hours' },
  { bgColor: '#FEF077', label: 'Fresh leads', tooltipText: 'Hello World!' },
  // { bgColor: '#F3EFD3', label: 'Open all day' },
  { bgColor: '#2A5068', label: 'After hours leads' },
  // { bgColor: '#B5B6BD', label: 'Closed all day' },
  { bgColor: '#98C1D3', label: 'Reattempts' },
];

const useStyles = makeStyles(theme => ({
  container: {},
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  keyMap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  circle: {
    width: 12,
    height: 12,
    // borderRadius: '50%',
    marginRight: 12,
  },
  label: {
    fontSize: 14,
    marginRight: 5,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'end',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
  },
  scheduleRowWrapper: {
    margin: theme.spacing(5, 0),
    '& > *:not(:last-child)': {
      marginBottom: ({ mode }) => theme.spacing(mode === 'edit' ? 2.5 : 0),
    },
  },
  spacingTop: {
    marginTop: theme.spacing(3),
  }
}));

export default function CallSchedule({ data, groupId, isEditAllowed }) {
  const [mode, setMode] = useState('view');

  const [allSelected, setAllSelected] = useState();

  const [isChanged, setIsChanged] = useState(false);

  const classes = useStyles({ mode });

  const { updateGroup } = useGroupActions();

  const { data: timezones, isLoading } = useTimezonesData();

  const handleEdit = () => {
    setMode('edit');
  };

  const handleSave = groupData => {
    updateGroup(groupData);

    setMode('view');

    setAllSelected(null);
  };

  const formik = useFormik({
    initialValues: {
      timezone_id: data.timezone_id,
      selectedTimezone: data.timezone_id,
      workingTimes: data.working_hours,
      schedule: data.schedule,
    },
    validationSchema: yup.object().shape({
      timezone_id: yup.string().required(),
      selectedTimezone: yup.string().required(),
      workingTimes: yup.object().shape({
        ...weekDays.reduce(
          (acc, day) => ({
            ...acc,
            [day]: yup.object().shape({
              type: yup.string(),
              is_open: yup.number().required(),
              intervals: yup.array().of(
                yup.object().shape({
                  from: yup.string().required(),
                  to: yup.string().required(),
                }),
              ),
            }),
          }),
          {},
        ),
      }),
      schedule: yup.object().shape({
        ...weekDays.reduce(
          (acc, day) => ({
            ...acc,
            [day]: yup.object().shape({
              ...scheduleTypes.reduce(
                (acc, type) => ({
                  ...acc,
                  [type]: yup.array().of(
                    yup.object().shape({
                      from: yup.string(),
                      to: yup.string(),
                    }),
                  ),
                }),
                {},
              ),
            }),
          }),
          {},
        ),
      }),
    }),
    onSubmit: values => {
      // eslint-disable-next-line camelcase
      const { schedule, selectedTimezone, workingTimes, timezone_id } = values;

      const newWorkingHours = {}

      Object.keys(workingTimes).forEach(key => {
        const type = workingTimes[key].type ? workingTimes[key].type : null;

        switch (type) {
          case '1':
            newWorkingHours[key] = {};
            newWorkingHours[key].is_open = 1;
            newWorkingHours[key].intervals = [];

            break;
          case '2':
            newWorkingHours[key] = {};
            newWorkingHours[key].is_open = 0;
            newWorkingHours[key].intervals = [];

            schedule[key] = {
              fresh: [],
              after_hours: [],
              reattempts: [],
            }

            break;
          case '3':
            newWorkingHours[key] = {};
            newWorkingHours[key].is_open = 1;
            newWorkingHours[key].intervals = workingTimes[key].intervals;

            break;
          default:
            newWorkingHours[key] = {};
            newWorkingHours[key].is_open = workingTimes[key].is_open;
            newWorkingHours[key].intervals = workingTimes[key].intervals;

            break;
        }

      });

      handleSave({
        ...data,
        schedule,
        timezone_id,
        working_hours: newWorkingHours,
        allSelected
      });
    },
  });

  const handleChangeWorkingType = (dayType, day, value) => {
    formik.setFieldValue(`workingTimes.${day}.is_open`, value, false);
    formik.setFieldValue(`workingTimes.${day}.type`, dayType, false);
  };

  const handleChangeWorkingTime = (day, value) => {
    const workingIntervals = convertIntervalToTime(value);

    formik.setFieldValue(`workingTimes.${day}.intervals`, workingIntervals, false);
  };

  const handleChangeSchedule = (day, type, value) => {
    const scheduleIntervals = convertIntervalToTime(value);

    formik.setFieldValue(`schedule.${day}.${type}`, scheduleIntervals, false);
  };

  const handleSelectTimezone = (id) => {
    formik.setFieldValue('timezone_id', id);
    setIsChanged(true);
  }

  const getTimezoneValue = () => {
    if (!timezones || timezones.length < 1) {
      return null;
    }

    const filteredTimezone = timezones.filter(timezone => (timezone.id == formik.values.timezone_id));

    const { name, utc_offset } = filteredTimezone[0]

    const value = `UTC ${utc_offset}:00 ${name}`

    return value;
  };

  const handleCancelButton = () => {
    formik.setValues(formik.initialValues, false);

    setMode('view');
  };

  const handleSaveButton = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    formik.setFieldValue('timezone_id', data.timezone_id);
    formik.setFieldValue('selectedTimezone', data.timezone_id);
    formik.setFieldValue('workingTimes', data.working_hours);
    formik.setFieldValue('schedule', data.schedule);
  }, [data]);

  const clickSaveButton = () => {
    handleSaveButton();
    setIsChanged(false);
  }

  const clickCancelButton = () => {
    handleCancelButton();
    setIsChanged(false);
  }

  return (
    <div className={classes.container}>
      <TooltipedText tooltipText="Set the times your Reps/Groups are available to take calls for each lead call type"
        tooltipStyle={{ maxWidth: 328 }}>
        <Typography variant="subtitle2">
          Call Schedule
        </Typography>
      </TooltipedText>

      <Grid container className={classes.spacingTop}>
        <Grid item xs={6}>
          {
            mode === 'view'
              ? (<Typography variant="body2">
                {getTimezoneValue()}
              </Typography>)
              : (
                <Box maxWidth={400}>
                  <TextField
                    id="selectedTimezone"
                    name="selectedTimezone"
                    label="Timezone"
                    variant="outlined"
                    color="secondary"
                    value={formik.values.timezone_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.timezone_id && Boolean(formik.errors.timezone_id)}
                    fullWidth
                    select
                  >
                    {timezones.map(timezone => (
                      <MenuItem
                        key={`timezone-${timezone.id}-${groupId}`}
                        value={timezone.id}
                        onClick={() => handleSelectTimezone(timezone.id)}
                      >
                        {`UTC ${timezone.utc_offset}:00 ${timezone.name}`}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              )
          }
        </Grid>

        <Grid item xs={3}>
          <Grid container spacing={1}>
            {
              keyMaps.map((keyMap, index) => (
                <Grid key={`${index}-${groupId}`} item xs={12}>
                  <div className={classes.keyMap}>
                    <span className={classes.circle} style={{ backgroundColor: keyMap.bgColor }} />

                    <Typography className={classes.label}>{keyMap.label}</Typography>
                  </div>
                </Grid>
              ))}
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <div className={classes.buttonsWrapper}>
            {
              mode === 'view'
                ? (
                  <>
                    {isEditAllowed &&
                      <Button variant="contained" color="primary" size="large" disableElevation onClick={handleEdit}>
                        Edit
                      </Button>
                    }
                  </>
                )
                : (<> {isChanged === true ?
                  (<div className={classes.buttonsWrapper}>
                    <Button color="primary" size="large" disableElevation onClick={clickCancelButton}>
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disableElevation
                      disabled={!formik.isValid}
                      onClick={clickSaveButton}
                    >
                      Save
                    </Button>
                  </div>) : (<></>)
                }
                </>)
            }
          </div>
        </Grid>
      </Grid>

      <div className={classes.scheduleRowWrapper}>
        {
          Object.keys(formik.values.workingTimes).map((day, idx) => {
            const { workingTimes: workingHours, schedule } = formik.values;

            const dateByDay = getWeekDay(day);

            const workingTimes = convertTimeToInterval(dateByDay, workingHours[day].intervals);

            const scheduleLeadTypes = Object.entries(schedule[day]).reduce(
              (acc, [key, scheduleInterval]) => ({
                ...acc, [key]: convertTimeToInterval(dateByDay, scheduleInterval)
              }),
              {},
            );

            const isDayOpen = Boolean(workingHours[day].is_open);

            const isDayCustom = Boolean(workingHours[day].intervals.length);

            let dayType = '2';

            if (formik.values.workingTimes[day].type) {
              dayType = formik.values.workingTimes[day].type;
            } else if (isDayCustom && isDayOpen) {
              dayType = '3';
            } else if (isDayOpen) {
              dayType = '1';
            }

            return (
              <ScheduleRow
                key={`${idx}-${groupId}`}
                mode={mode}
                groupId={groupId}
                workingDay={day}
                name={day}
                dayScheduleData={{ workingTimes, type: dayType, scheduleLeadTypes }}
                workingTimeInterval={setDayToInterval(timeInterval, idx + 1)}
                onWorkingTypeChange={handleChangeWorkingType}
                onWorkingTimeChange={handleChangeWorkingTime}
                onScheduleChange={handleChangeSchedule}
                allSelected={allSelected}
                setAllSelected={setAllSelected}
                handleCancelButton={handleCancelButton}
                handleSaveButton={handleSaveButton}
                disabledButton={!formik.isValid}
                showButton={false}
              />
            );
          })
        }
      </div>
    </div>
  );
}
