/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { makeStyles } from '@material-ui/core';

import { useRepsData } from 'src/features/routingRules/rep/state/hooks';
import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { GroupRepsList } from './GroupRepsList';


const useStyles = makeStyles(() => ({
  loadingContainer: {
    width: '100%',
    minHeight: '100%',
  },
}));

const GroupReps = repsProps => {
  const classes = useStyles();
  const { data, isLoading } = useRepsData();

  return (
    <LoadingContainer classNames={classes.loadingContainer} loading={isLoading}>
      <GroupRepsList {...repsProps} data={data} />
    </LoadingContainer>
  );
};

export default GroupReps;
