/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { makeStyles } from '@material-ui/core';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { GroupLocationsInput } from './GroupLocationsInput';

import { useFetchLocations, useLocationsActions, useLocationsData } from '../../state/hooks';

const useStyles = makeStyles(() => ({
  loadingContainer: {
    minHeight: '100%',
  },
}));

export const GroupLocations = locationsProps => {
  const classes = useStyles();
  const { data, isLoading } = useLocationsData();
  const { createLocation, removeLocation, updateLocation } = useLocationsActions();

  useFetchLocations();

  return (
    <LoadingContainer classNames={classes.loadingContainer} loading={isLoading}>
      <GroupLocationsInput
        {...locationsProps}
        data={data}
        onCreateLocation={createLocation}
        onRemoveLocation={removeLocation}
        onUpdateLocation={updateLocation}
      />
    </LoadingContainer>
  );
};
