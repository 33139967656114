/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { RepsList } from './RepsList';

export const Reps = ({ data, isLoading }) => {
  return (
    <LoadingContainer loading={isLoading}>
      {data && !!data.length &&
        <RepsList data={data} />
      }
    </LoadingContainer>
  );
};
