import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles, Typography, Grid, ButtonBase, TextField, FormHelperText, IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import Alert from 'src/components/alert';

import DeleteIcon from 'src/assets/icons/delete.svg';
import EditIconAlt from 'src/assets/icons/edit.svg';

const useStyles = makeStyles(theme => ({
  row: {
    backgroundColor: '#F6F6F6',
    padding: theme.spacing(3),
    borderRadius: 4,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}));

export const GroupLocationsInputLabelRow = ({ id, label, onEdit, onDelete, global }) => {
  const classes = useStyles();
  const [isView, setIsView] = useState(true);

  const formik = useFormik({
    initialValues: {
      id,
      label,
    },
    validationSchema: yup.object().shape({
      label: yup.string().required(),
    }),
    onSubmit: (values, actions) => {
      actions.resetForm({ values });
      actions.setSubmitting(false);

      onEdit(values);
    },
  });

  const toggleRepresentation = () => {
    setIsView(isView => !isView);
  };

  const handleSubmitEdit = () => {
    toggleRepresentation();

    formik.submitForm();
  };

  const handleCancelEdit = () => {
    toggleRepresentation();

    formik.resetForm({ values: { id, label } });
  };

  return (
    <div className={classes.row}>
      <Grid container spacing={2} alignItems="center">
        {isView ? (
          <>
            <Grid item xs>
              <Typography>{label}</Typography>
            </Grid>

            {global ? (
              <Grid item>
                <Typography>All leads</Typography>
              </Grid>
            ) : (
              <>
                <Grid item>
                  <ButtonBase onClick={toggleRepresentation}>
                    <EditIconAlt />
                  </ButtonBase>
                </Grid>

                <Grid item>
                  <ButtonBase onClick={onDelete}>
                    <DeleteIcon />
                  </ButtonBase>
                </Grid>
              </>
            )}
          </>
        ) : (
          <>
            <Grid item xs>
              <TextField
                id="label"
                variant="outlined"
                label="Label"
                size="small"
                color="secondary"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.label}
                error={formik.errors.label && formik.touched.label}
              />

              {formik.errors.label && formik.touched.label && (
                <FormHelperText component="div">
                  <Alert severity="error">{formik.errors.label}</Alert>
                </FormHelperText>
              )}
            </Grid>

            <Grid item>
              <IconButton color="primary" size="small" onClick={handleSubmitEdit}>
                <DoneIcon />
              </IconButton>
            </Grid>

            <Grid item>
              <IconButton color="primary" size="small" onClick={handleCancelEdit}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
