import { useFormik } from 'formik';
import hexToRgba from 'hex-to-rgba';
import React, { useState } from 'react';
import { TwitterPicker } from 'react-color';
import * as yup from 'yup';

import { makeStyles, Typography, TextField, Grid, Button, InputAdornment, Popover, ButtonBase } from '@material-ui/core';

import TooltipedText from 'src/components/tooltipedText';
import GroupRepsList from '../../createGroup/list/GroupReps';
import { GroupLocations } from '../../createGroup/locations/GroupLocations';
import { GroupReps } from '../../createGroup/reps/GroupReps';

const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingBottom: theme.spacing(5),
  },
  labelColor: {
    height: 20,
    width: 20,
    borderRadius: 4,
  },
}));

export default function EditGroup({ groupId, data, onCancel, onSave }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const formik = useFormik({
    initialValues: {
      groupName: data.name,
      reps: data.reps,
      color: data.color || '#9900EF',
      routingRules: data.location_id,
    },
    validationSchema: yup.object().shape({
      groupName: yup.string().required(),
      color: yup.string().required(),
      reps: yup.array().of(
        yup.object().shape({
          id: yup.number(),
          enabled: yup.number(),
        }),
      ),
      // routingRules: yup.string().required(),
      // routingRules: yup.string(),
    }),
    onSubmit: values => {
      const { groupName, reps, routingRules, color } = values;

      onSave({
        ...data,
        name: groupName,
        reps,
        location_id: routingRules,
        color,
      });
    },
  });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeColor = colorString => {
    formik.setFieldValue('color', colorString.hex, true);
  };

  const handleUpdateRep = ({ id, ...rep }) => {
    const { reps } = formik.values;

    const repIdx = reps.findIndex(repItem => repItem.id === id);

    formik.setFieldValue(
      'reps',
      [
        ...reps.slice(0, repIdx),
        {
          ...reps[repIdx],
          ...rep
        },
        ...reps.slice(repIdx + 1)
      ],
      false
    );
  };

  const handleUpdatePriority = ({ oldIndex, newIndex }) => {
    let reps = [];

    let temp = {};

    if (oldIndex === newIndex) {
      reps = formik.values.reps;
    } else if (oldIndex < newIndex) {
      formik.values.reps.forEach((rep, index) => {
        if (index === oldIndex) {
          temp = rep;
        } else if (index > oldIndex && index < newIndex) {
          reps[index - 1] = rep;
        } else if (index === newIndex) {
          reps[index - 1] = rep;
          reps[newIndex] = temp;
        } else {
          reps[index] = rep;
        }
      });
    } else {
      formik.values.reps.forEach((rep, index) => {
        if (index === newIndex) {
          reps[index] = formik.values.reps[oldIndex];
        } else if (index > newIndex && index <= oldIndex) {
          reps[index] = formik.values.reps[index - 1];
        } else {
          reps[index] = rep;
        }
      });
    }

    formik.setFieldValue('reps', [...reps], false);
  };

  const handleDelete = id => {
    formik.setFieldValue(
      'reps',
      formik.values.reps.filter(rep => rep.id !== id),
      false,
    );
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={5}>
            <TextField
              label="Group name"
              variant="outlined"
              size="small"
              color="secondary"
              fullWidth
              onChange={formik.handleChange}
              name="groupName"
              value={formik.values.groupName}
              onBlur={formik.handleBlur}
              error={formik.touched.groupName && Boolean(formik.errors.groupName)}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.wrapper}>
        <Typography paragraph variant="body2">
          Reps
        </Typography>

        <Grid container alignItems="center">
          <Grid item xs="auto" md={12}>
            <TooltipedText tooltipText="To add new or delete existing reps, click on the 'Rep' tab">
              <Grid item xs="auto" md={5}>
                <GroupReps
                  name="reps"
                  placeholder="Select rep to assign to this group"
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onChange={formik.setFieldValue}
                  value={formik.values.reps}
                  onBlur={formik.handleBlur}
                  error={formik.touched.reps && Boolean(formik.errors.reps)}
                  fullWidth
                />
              </Grid>
            </TooltipedText>
          </Grid>
        </Grid>
      </div>

      {
        Boolean(formik.values.reps.length) && (
          <div className={classes.wrapper}>
            <Grid container alignItems="center" spacing={2}>
              <GroupRepsList groupId={groupId} selected={formik.values.reps} onUpdate={handleUpdateRep} onDelete={handleDelete} onUpdatePriority={handleUpdatePriority} />
            </Grid>
          </div>
        )
      }

      <div className={classes.wrapper}>
        <Typography paragraph variant="body2">
          Routing rules
        </Typography>

        <Grid container alignItems="center">
          <Grid item xs md={5}>
            <GroupLocations
              label="Routing rules"
              name="routingRules"
              value={formik.values.routingRules}
              onChange={formik.setFieldValue}
              onBlur={formik.handleBlur}
              error={formik.touched.routingRules && Boolean(formik.errors.routingRules)}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.wrapper}>
        <Typography paragraph variant="body2">
          Color
        </Typography>

        <Grid container alignItems="center">
          <Grid item xs md={5}>
            <TextField
              size="small"
              label="Color"
              variant="outlined"
              color="secondary"
              name="color"
              // disabled
              error={formik.touched.color && Boolean(formik.errors.color)}
              onBlur={formik.handleBlur}
              value={formik.values.color?.toUpperCase()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ButtonBase
                      onClick={handleClick}
                      className={classes.labelColor}
                      style={{
                        backgroundColor: hexToRgba(formik.values.color, 0.6),
                        border: `1px solid ${formik.values.color}`,
                      }}
                    />

                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <TwitterPicker
                        triangle="hide"
                        color={formik.values.color}
                        onChange={handleChangeColor}
                      />
                    </Popover>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </div>


      <Grid container justifyContent="flex-end" spacing={3}>
        <Grid item>
          <Button color="primary" size="large" disableElevation onClick={onCancel}>
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            onClick={formik.handleSubmit}
            disabled={!formik.isValid}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
