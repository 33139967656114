/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';

import TooltipedText from 'src/components/tooltipedText';
import SwitchTag from 'src/components/switchTag'
// import ViewTrialAttempts from './viewTrial';

const useStyles = makeStyles(theme => ({
  spacer: {
    marginBottom: theme.spacing(3),
  },
  spacerTop: {
    marginTop: theme.spacing(3),
  },
  labelFirst: {
    backgroundColor: '#FEF077',
    border: '1px solid #FEF077',
    color: '#000000',
    padding: '9px 12px',
    position: 'relative',
    fontWeight: '400',
    fontSize: '14px',
    height: '45px',
    width: 'calc(100% + 1px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    height: '45px',
    backgroundColor: '#98C1D3',
    color: '#fff',
    padding: '9px 12px',
    position: 'relative',
    fontWeight: '400',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelDay: {
    padding: '9px 12px',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
  },
  labelTime: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#000000',
    marginTop: '11px',
  },
  labelDayColumn: {
    width: '110px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelDivider: {
    top: '50%',
    right: '-40px',
    border: '1px solid #98C1D3',
    position: 'absolute',
    maxWidth: '40px',
    width: '100%',
  },
  spacer2: {
    marginBottom: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  callAttemptsLabel: {
    marginBottom: theme.spacing(3),
    width: 325,
  },
  attempts: {
    maxWidth: '925px',
  },
  attemptListWrap: {
    marginRight: '40px',
    marginBottom: '20px',
    width: '200px',
  },
  attemptListWrapNoMargin: {
    marginBottom: '20px',
    width: '200px',
  },
  dayWrap: {
    background: '#F8F8F8',
    border: '1px solid #F2F2F2',
    borderRadius: '3px',
    padding: '20px 20px 0 20px',
    marginBottom: '38px',
  },
  spacer3: {
    marginTop: theme.spacing(3),
    marginBottom: '25px',
  },
  disabled: {
    opacity: 0.3,
  },
}));

const callDistributionTypes = [];
callDistributionTypes[1] = 'Priority ringing';
callDistributionTypes[2] = 'Round robin';
callDistributionTypes[3] = 'Simultaneous';

export default function ViewCallSettings({ groupId, data, onEdit, isEditAllowed }) {
  const classes = useStyles();

  const getAttemptNumber = (day, index) => {
    let length = 0;

    data.reattempts.forEach((reattempt) => {
      if (reattempt.day < day) {
        length += reattempt.attempts.length;
      }
    });

    const numberAttempt = length + index + 1;

    switch (numberAttempt) {
      case 1:
        return `${numberAttempt}st`;
      case 2:
        return `${numberAttempt}nd`;
      case 3:
        return `${numberAttempt}rd`;
      default:
        return `${numberAttempt}th`;
    }
  }

  return (
    <>
      <Grid container spacing={3} className={classes.spacer}>
        <Grid item md>
          <TooltipedText tooltipText="Set the duration between each lead call attempt" className={classes.callAttemptsLabel}>
            <Typography variant="subtitle2">
              Call Attempt Intervals
            </Typography>
          </TooltipedText>

          <Fragment key={`first-call-${groupId}`}>
            <Grid className={classes.spacer3} container spacing={2} alignItems="center">
              <Grid item xs="auto">
                <SwitchTag checked={Boolean(Number(data.reattempts_disabled))} />
              </Grid>

              <Grid item xs>
                <Typography>Reattempts</Typography>
              </Grid>
            </Grid>
          </Fragment>

          {
            (data.reattempts.map((reattempt, indexDay) => (
              reattempt.attempts && (
                <Fragment
                  key={`reattempts-timeouts-${groupId}-${reattempt.day}`}
                >
                  <Grid
                    className={classes.dayWrap}
                    container
                    spacing={2}
                    style={
                      indexDay + 1 === data.reattempts.length
                        ? { marginBottom: 0 }
                        : {}
                    }
                  >
                    <Grid
                      item
                      xs="auto"
                      className={
                        clsx(
                          // eslint-disable-next-line eqeqeq
                          (data.reattempts_disabled == 1 && reattempt.day != 0) && classes.disabled,
                          classes.labelDayColumn
                        )
                      }
                    >
                      <Typography align="center" className={classes.labelDay}>
                        Day {reattempt.day + 1}
                      </Typography>
                    </Grid>

                    <Grid item xs="auto" className={classes.attempts}>
                      <Grid container spacing={2} alignItems="center">
                        {
                          reattempt.attempts?.map((timeout, index) => (
                            <Grid
                              key={index}
                              className={
                                clsx(
                                  (
                                    // eslint-disable-next-line eqeqeq
                                    (data.reattempts_disabled == 1 && !(reattempt.day == 0 && index == 0))
                                    && classes.disabled
                                  ),
                                  (
                                    (index !== (reattempt.attempts.length - 1) && reattempt.attempts.length > 1)
                                      ? classes.attemptListWrap
                                      : classes.attemptListWrapNoMargin
                                  )
                                )
                              }

                            >
                              <Grid
                                className={
                                  reattempt.day === 0 && index === 0
                                    ? classes.labelFirst
                                    : classes.label
                                }
                                xs="auto"
                                align="center"
                                item
                              >
                                {
                                  (index < (reattempt.attempts.length - 1) && reattempt.attempts.length > 1) && (<Grid item className={classes.labelDivider} />)
                                }

                                {getAttemptNumber(reattempt.day, index)} attempt
                              </Grid>
                              {
                                indexDay < 1 && (<Grid item xs="auto" align="center" className={classes.labelTime}>
                                  {`${timeout.hours} hrs ${timeout.minutes} mins`}
                                </Grid>)
                              }
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>)
            )))
          }

          {/* <ViewTrialAttempts index={data.reattempts[0]?.attempts?.length} /> */}
        </Grid>
      </Grid>

      {isEditAllowed &&
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" color="primary" size="large" disableElevation onClick={onEdit}>
              Edit
            </Button>
          </Grid>
        </Grid>
      }
    </>
  );
}
