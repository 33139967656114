/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { makeStyles, Tabs, Tab } from '@material-ui/core';
import { navigate } from 'gatsby';
import queryString from 'query-string';

import RepIcon from 'src/assets/icons/rep.svg';
import GroupIcon from 'src/assets/icons/group.svg';

import Rep from './rep';
import Group from './group';

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(-2),
  },
  tab: {
    minHeight: 50,
    backgroundColor: '#E8E8E8',
    minWidth: 'unset',
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(1.5, 3),
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      fontSize: 15,
    },
    '&.MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
      margin: 0,
    },
    '&.MuiTab-textColorInherit': {
      opacity: 1,
    },
    '&.MuiTab-textColorInherit.Mui-selected': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

export const RoutingRules = () => {
  const subpage = queryString.parse(location.search) && queryString.parse(location.search).page ? queryString.parse(location.search).page : false;
  const [value, setValue] = React.useState(0);
  React.useEffect(() => {
    switch (subpage) {
      case 'rep':
        setValue(0);
        break;
      case 'group':
        setValue(1);
        break;
    }
  }, [subpage]);


  const classes = useStyles();

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const navigateRep = () => {
    navigate('/me/settings/routing-rules?page=rep');
  };

  const navigateGroup = () => {
    navigate('/me/settings/routing-rules?page=group');
  };

  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange} className={classes.tabs} TabIndicatorProps={{ hidden: true }}>
        <Tab label="Rep" icon={<RepIcon />} className={classes.tab} onClick={navigateRep} />

        <Tab label="Group" icon={<GroupIcon />} className={classes.tab} onClick={navigateGroup} />
      </Tabs>

      {value === 0 && <Rep />}

      {value === 1 && <Group />}
    </div>
  );
};
