/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { addMinutes, subMinutes, isWithinInterval, format, isEqual } from 'date-fns';
import eachMinuteOfInterval from 'date-fns/eachMinuteOfInterval';
import clsx from 'clsx';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  Typography,
  Select,
  MenuItem,
  ButtonBase
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import DeleteIcon from 'src/assets/icons/delete.svg';

import TooltipedText from 'src/components/tooltipedText';

const scheduleLeadTypes = {
  fresh: 'freshLeads',
  after_hours: 'afterHoursLeads',
  reattempts: 'reattemps',
};
const scheduleLeadTypeColors = {
  freshLeads: { backgroundColor: '#FEF077', label: 'Fresh leads', color: '#000000' },
  afterHoursLeads: { backgroundColor: '#2A5068', label: 'After hours leads', color: '#ffffff' },
  reattemps: { backgroundColor: '#98C1D3', label: 'Reattempts', color: '#ffffff' },
};

const useStyles = makeStyles(theme => ({
  scheduleEdit: {
    margin: theme.spacing(5, 0),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2.5),
    },
  },
  day: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #202239',
    backgroundColor: '#3C3D52',
    width: 65,
    minWidth: 65,
    height: 35,
    color: '#FFFFFF',
    marginRight: theme.spacing(1),
  },
  dayPlanning: {
    display: 'flex',
    alignItems: 'baseline',
    backgroundColor: '#F8F8F8',
    padding: theme.spacing(1.2, 2.2),
    paddingBottom: theme.spacing(2),
    border: '1px solid #EBEBEB',
    borderRadius: 5,
  },
  radioGroup: {
    '& > .MuiFormControlLabel-root': {
      marginRight: theme.spacing(5),
    },
  },
  radioWrapper: {
    borderRadius: 5,
    opacity: 0.8,
    padding: theme.spacing(2, 1.5),
    '& > .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: theme.spacing(1.5),
    },
    '& > .MuiFormControlLabel-root > .MuiIconButton-root': {
      padding: 0,
      paddingRight: theme.spacing(1.5),
    },
  },
  workingRangesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    padding: theme.spacing(1.5, 3),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(0.5),
    },
  },
  workingRangesSelectsWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& *:not(:last-child)': {
      marginBottom: 0,
      marginRight: theme.spacing(0.5),
      '@media screen and (max-width: 1520px)': {
        marginBottom: theme.spacing(0.5),
        marginRight: 0,
      },
    },
    '& .MuiOutlinedInput-root': {
      width: 114,
      height: 42,
    },
  },
  disabledNewWorkingTime: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  rangeLine: {
    width: 12,
    border: '1px solid #3C3D52',
  },
  newWorkingTimeWrapper: {
    display: 'flex',
    width: 'fit-content',
    marginTop: theme.spacing(1.75),
    cursor: 'pointer',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(0.6),
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
    // '&:hover': {
    //   '&>.MuiTypography-root': {
    //     borderBottom: '1px solid',
    //     borderBottomColor: theme.palette.primary.main,
    //   },
    // },
  },
  addIconWrapper: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    width: 20,
    height: 20,
    position: 'relative',
  },
  addIcon: {
    fill: 'white',
    stroke: 'white',
    width: 11,
    height: 11,
    strokeWidth: 7,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  setTimeRageWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-around',
    // 'flex-wrap': 'wrap',
    '@media screen and (max-width: 1520px)': {
      flexDirection: 'column'
    }

  },
  setTimeRageWrapperRangeLine: {
    '@media screen and (max-width: 1520px)': {
      display: 'none',
    },
  },
  addIconLabel: {
    color: theme.palette.primary.main,
  },
  deleteIconWrapper: {
    height: 24,
    cursor: 'pointer',
  },
  deleteIcon: {
    fill: 'white',
  },
  leadTypesContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2.5),
    paddingLeft: theme.spacing(1.8),
  },
  leadTypesTitle: {
    fontSize: 15,
    color: '#3C3D52',
  },
  leadTypesWrapper: {
    display: 'flex',
    paddingTop: theme.spacing(3),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  leadType: {
    borderRadius: 5,
    flex: 1,
    padding: theme.spacing(2, 1.25),
    paddingLeft: theme.spacing(4),
  },
  label: {
    fontSize: 14,
    paddingBottom: theme.spacing(1.75),
  },
  number: {
    color: 'white',
    width: 10,
    paddingRight: 12,
  },
  tooltipAddDayPart: {
    // marginTop: 14,
  },
  wrapAddDayPart: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function ScheduleLeadType({
  day,
  scheduleLeadType,
  value,
  workingTimeInterval,
  type,
  workingTimes,
  onChange,
  name,
}) {
  const classes = useStyles();
  const morningLimit = '08:00:00';
  const eveningLimit = '21:00:00';

  const [data, setData] = useState(value);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [index, setIndex] = useState(value);
  const [openTimeDialog, setOpenTimeDialog] = useState(false);

  const handleCloseTimeDialog = () => {
    setOpenTimeDialog(false);
  };

  const handleApplyTimeDialog = () => {
    let newData = data ? [...data] : [];

    if (startTime) {
      if (!newData.length) {
        newData = [];
        newData.push({
          start: startTime,
          end: false
        });
      } else {
        newData[index] = {
          ...newData[index],
          start: startTime,
        };
      }
    } else if (endTime) {
      if (!newData.length) {
        newData = [];
        newData.push({ end: endTime, start: false });
      } else {
        newData[index].end = endTime;
      }
    } else {
      setOpenTimeDialog(false);
      return;
    }

    setData(newData);
    setStartTime(null);
    setEndTime(null);
    setOpenTimeDialog(false);
  };

  const handleSelectStartScheduleLeadTime = index => event => {
    const startDate = new Date(event.target.value);
    const startDateToTime = format(startDate, 'HH:mm:ss');

    if (startDateToTime < morningLimit || startDateToTime > eveningLimit) {
      setStartTime(startDate);
      setIndex(index);
      setOpenTimeDialog(true);
      return;
    }

    let newData = data ? [...data] : [];

    if (!newData.length) {
      newData = [];
      newData.push({
        start: startDate,
        end: false
      });
    } else {
      newData[index] = {
        ...newData[index],
        start: startDate,
      };
    }

    setData(newData);
  };

  const handleSelectEndScheduleLeadTime = index => event => {
    const endDate = new Date(event.target.value);
    const endDateToTime = format(endDate, 'HH:mm:ss');

    if (endDateToTime < morningLimit || endDateToTime > eveningLimit) {
      setEndTime(endDate);
      setIndex(index);
      setOpenTimeDialog(true);
      return;
    }

    let newData = data ? [...data] : [];

    if (!newData.length) {
      newData = [];
      newData.push({ end: endDate, start: false });
    } else {
      newData[index].end = endDate;
    }

    setData(newData);
  };

  const getStartScheduleLeadTimeHours = index => {
    const scheduleLeadTimes = [...data];

    const lastScheduleLead = [];

    switch (type) {
      case '3':
        if (index === 0) {
          workingTimes.forEach(workingTime => {
            lastScheduleLead.push({ start: new Date(workingTime.start), end: subMinutes(new Date(workingTime.end), 15) });
          });
        } else if (new Date(scheduleLeadTimes[index - 1].start) && new Date(scheduleLeadTimes[index - 1].end)) {
          const { end } = scheduleLeadTimes[index - 1];

          const BreakPoint = {};

          try {
            workingTimes.forEach(workingTime => {
              if (!isEqual(new Date(end), new Date(workingTime.end)) && isWithinInterval(new Date(end), workingTime)) {
                lastScheduleLead.push({ start: new Date(end), end: subMinutes(new Date(workingTime.end), 15) });
              } else if (!isEqual(new Date(end), new Date(workingTime.end)) && (new Date(end) < new Date(workingTime.start))) {
                lastScheduleLead.push({ start: new Date(workingTime.start), end: subMinutes(new Date(workingTime.end), 15) });
              }
            });

            throw BreakPoint;
          } catch (error) {
            if (error !== BreakPoint) {
              // eslint-disable-next-line no-console
              console.log(error);
            }
          }
        }

        break;
      default:
        lastScheduleLead.push({
          start: index === 0 ? workingTimeInterval.start : new Date(scheduleLeadTimes[index - 1].end),
          end: false,
        });

        lastScheduleLead[0].end = workingTimeInterval.end;

        break;
    }

    if (lastScheduleLead.length) {
      const mergedArrays = lastScheduleLead
        .filter(interval => interval && interval.start && interval.end)
        .map(interval => isEqual(new Date(interval.start), new Date(interval.end))
          ? [new Date(interval.start)]
          : eachMinuteOfInterval({ start: new Date(interval.start), end: new Date(interval.end) }, { step: 15 })
        )
        .flat();

      return mergedArrays;
    }

    return [];
  };

  const getEndScheduleLeadTimeHours = scheduleLeadTime => {
    let withinInterval = [];

    if (type === '3') {
      const BreakPoint = {};
      try {
        workingTimes.forEach(workingTime => {
          if (scheduleLeadTime.start && isEqual(addMinutes(new Date(scheduleLeadTime.start), 15), new Date(workingTime.end))) {
            withinInterval = [workingTime.end];
          } else if (scheduleLeadTime.start && isWithinInterval(new Date(scheduleLeadTime.start), { start: new Date(workingTime.start), end: new Date(workingTime.end) })) {
            withinInterval = eachMinuteOfInterval(
              { start: addMinutes(new Date(scheduleLeadTime.start), 15), end: new Date(workingTime.end) },
              { step: 15 }
            );
          }
        });
      } catch (error) {
        if (error !== BreakPoint) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }
    } else if (scheduleLeadTime.start) {
      withinInterval = !isEqual(addMinutes(new Date(scheduleLeadTime.start), 15), new Date(workingTimeInterval.end))
        ? eachMinuteOfInterval(
          { start: addMinutes(new Date(scheduleLeadTime.start), 15), end: new Date(workingTimeInterval.end) },
          { step: 15 },
        )
        : [workingTimeInterval.end];
    }
    return withinInterval;
  };

  const addNewLeadTime = () => {
    let newData = data ? [...data] : [];

    if (!Array.isArray(newData)) {
      newData = [];
    }
    newData.push({ start: false, end: false });

    setData(newData);
  };

  const deleteLeadTime = index => {
    const newData = data ? [...data] : [];
    newData.splice(index, 1);

    setData(newData);
  };

  useEffect(() => {
    onChange(name, data);
  }, [data]);

  useEffect(() => {
    setData(value);
  }, [value]);

  const checkAdditionTimeAvailability = () => {
    if (!data && workingTimes) {
      return false;
    }

    if (
      data.length > 0 &&
      ((type === '3' &&
        workingTimes.length > 0 &&
        isEqual(new Date(data[data.length - 1].end), new Date(workingTimes[workingTimes.length - 1].end))) ||
        (type === '1' && isEqual(new Date(data[data.length - 1].end), new Date(workingTimeInterval.end))))
    ) {
      return false;
    }

    if (data.length < 3) {
      return true;
    }

    return false;
  };

  return (
    <div
      className={clsx(classes.workingRangesWrapper, classes.leadType)}
      style={{ backgroundColor: scheduleLeadTypeColors[scheduleLeadTypes[scheduleLeadType]].backgroundColor }}
    >
      <Typography className={classes.label} style={{ color: scheduleLeadTypeColors[scheduleLeadTypes[scheduleLeadType]].color }}>
        {scheduleLeadTypeColors[scheduleLeadTypes[scheduleLeadType]].label}
      </Typography>

      {Array.isArray(data) &&
        data.map((scheduleLeadTime, index) => (
          <div key={`${scheduleLeadTime}_${index}`} className={classes.workingRangesSelectsWrapper}>
            {/* { JSON.stringify(scheduleLeadTime) } */}
            {
              (type !== '1' && (
                <div className={classes.number}>{index + 1}</div>
              ))
            }

            <div className={classes.setTimeRageWrapper}>
              <Select
                value={scheduleLeadTime.start || ''}
                disabled={type === '1'}
                variant="outlined"
                color="secondary"
                onChange={handleSelectStartScheduleLeadTime(index)}
              >
                {getStartScheduleLeadTimeHours(index).map(workingDayTime => (
                  <MenuItem key={`start_${scheduleLeadTime}_${index}_${workingDayTime}`} value={workingDayTime.toString()}>
                    {format(workingDayTime, 'h:mm aa')}
                  </MenuItem>
                ))}
              </Select>

              <div className={clsx(classes.rangeLine, classes.setTimeRageWrapperRangeLine)} style={{ borderColor: 'white' }} />

              <Select
                value={scheduleLeadTime.end || ''}
                variant="outlined"
                color="secondary"
                onChange={handleSelectEndScheduleLeadTime(index)}
                disabled={!scheduleLeadTime.start || type === '1'}
              >
                {getEndScheduleLeadTimeHours(scheduleLeadTime).map(workingDayTime => (
                  <MenuItem key={`end_${scheduleLeadTime}_${index}_${workingDayTime}`} value={workingDayTime.toString()}>
                    {format(workingDayTime, 'h:mm aa')}
                  </MenuItem>
                ))}
              </Select>
            </div>

            {
              (type === '1'
                ? (<TooltipedText tooltipText="Please switch to Custom working hours mode to make changes">
                  <></>
                </TooltipedText>)
                : (<div className={classes.deleteIconWrapper} onClick={() => deleteLeadTime(index)}>
                  <DeleteIcon className={classes.deleteIcon} fontSize="medium" />
                </div>)
              )
            }
          </div>
        ))}

      <div className={classes.wrapAddDayPart}>
        {
          (type !== '1' && (
            <Button
              style={{ minWidth: 0, padding: '10px 15px' }}
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              disabled={
                !checkAdditionTimeAvailability() || (type === '3' && (
                  !workingTimes.map(ele => ele.start && ele.end).reduce((prev, next) => prev && next) ||
                  !data.map(ele => ele.start && ele.end).reduce((prev, next) => prev && next, true)
                )) ||
                (type === '1' && !data.map(ele => ele.start && ele.end).reduce((prev, next) => prev && next, true))
              }
              onClick={addNewLeadTime}
            >
              + Add
            </Button>
            // <ButtonBase
            //   className={classes.newWorkingTimeWrapper}
            //   onClick={addNewLeadTime}
            //   disableRipple
            // disabled={
            //   !checkAdditionTimeAvailability() || (type === '3' && (
            //     !workingTimes.map(ele => ele.start && ele.end).reduce((prev, next) => prev && next) ||
            //     !data.map(ele => ele.start && ele.end).reduce((prev, next) => prev && next, true)
            //   )) ||
            //   (type === '1' && !data.map(ele => ele.start && ele.end).reduce((prev, next) => prev && next, true))
            // }
            // >
            //   <div className={classes.addIconWrapper}>
            //     <AddIcon className={classes.addIcon} />
            //   </div>

            //   <Typography className={classes.addIconLabel} style={{ color: scheduleLeadTypeColors[scheduleLeadTypes[scheduleLeadType]].color }}>
            //     Add day part
            //   </Typography>
            // </ButtonBase>
          ))
        }

        {
          ((checkAdditionTimeAvailability() || (type === '3' && (
            !workingTimes.map(ele => ele.start && ele.end).reduce((prev, next) => prev && next) ||
            !data.map(ele => ele.start && ele.end).reduce((prev, next) => prev && next, true)
          )) ||
            (type === '1' && !data.map(ele => ele.start && ele.end).reduce((prev, next) => prev && next, true))) && data.length < 3) && type !== '1' && (
            <div className={classes.tooltipAddDayPart}>
              <TooltipedText tooltipText="Create day-parts to turn lead calls on and off throughout your business day">
                <></>
              </TooltipedText>
            </div>
          )
        }

        {
          data && data.length === 3 && (
            <div className={classes.tooltipAddDayPart}>
              <TooltipedText tooltipText="You have reached the limit"><></></TooltipedText>
            </div>
          )
        }
      </div>

      <Dialog
        open={openTimeDialog}
        onClose={handleCloseTimeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to select a non-working time?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            size="large"
            disableElevation
            onClick={handleCloseTimeDialog}
          >
            No
          </Button>

          <Button
            variant="contained"
            color="secondary"
            size="large"
            disableElevation
            onClick={handleApplyTimeDialog}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
