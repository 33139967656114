/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React, { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Grid, makeStyles } from '@material-ui/core';

import { GroupRepsItem } from './GroupRepsItem';

const useStyles = makeStyles(() => ({
  nolist: {
    listStyleType: 'none',
    userSelect: 'none',
    margin: 0,
    padding: 0,
  },
  nolistItem: {
    listStyleType: 'none',
    userSelect: 'none',
    margin: 0,
    padding: 0,
    border: '1px solid #e6e6e6',
    borderRadius: '4px',
  },
}));

export const GroupRepsList = ({ groupId, data, selected, onUpdate, onDelete, onUpdatePriority, view }) => {
  const classes = useStyles();

  const handleDelete = id => {
    onDelete(id);
  };

  const handleUpdate = (id, rep) => {
    onUpdate({ id, ...rep });
  };

  const handleUpdatePriority = (params => {
    onUpdatePriority(params);
  });

  const SortableItem = SortableElement(({ value, rep, index }) => {
    return (<li className={classes.nolistItem}>
      <Grid key={`${groupId}-item-${index}-${value.id}`} item xs={12}>
        <GroupRepsItem
          {...rep}
          isEnabled={Boolean(Number(value.enabled))}
          onUpdate={repProps => handleUpdate(value.id, repProps)}
          onDelete={handleDelete}
          view={view}
        />
      </Grid>
    </li>);
  });

  const SortableList = SortableContainer(() => {
    return (<ul className={classes.nolist} key={`${groupId}-reps-list`}>      {
        selected.map((selectedItem, index) =>
          data
            .filter(rep => rep.id === selectedItem.id)
            .map(rep => (
              // eslint-disable-next-line react/no-array-index-key
              <SortableItem key={`${groupId}-list-${index}-${selectedItem.id}`} index={index} value={selectedItem} rep={rep} />
            )),
        )}
    </ul>);
  });

  class SortableComponent extends Component {
    onSortEnd = ({ oldIndex, newIndex }) => {
      handleUpdatePriority({ oldIndex, newIndex })
    };

    render() {
      // eslint-disable-next-line react/no-this-in-sfc
      return <SortableList distance={1} items={selected} onSortEnd={this.onSortEnd} />;
    }
  }

  return onUpdatePriority
    ? (<SortableComponent />)
    : (
      selected.map(({ id, enabled }, index) =>
        data
          .filter(rep => rep.id === id)
          .map(rep => (
            <Grid key={index} item xs={12}>
              <GroupRepsItem
                {...rep}
                isEnabled={Boolean(Number(enabled))}
                onUpdate={repProps => handleUpdate(id, repProps)}
                onDelete={() => handleDelete(id)}
                view={view}
              />
            </Grid>
          ))
      ))
}
